import { useEffect } from "react";

/*
 * Using useEffect directly makes it not clear if the implementer forgot to specify deps or if they intended to not care about
 * stale dependencies. So useMountEffect makes the intent explicit: we want to do nothing if the function's dependencies change.
 *
 * NOTE: if `fun` depends on state that changes in a subsequent render after mounting, useMountEffect will *not* re-run.
 */
export function useMountEffect(fun) {
  // eslint-disable-next-line
  return useEffect(fun, []);
}
