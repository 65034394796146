import { createContext, useContext } from "react";

interface Identity {
  id: string;
}

export const IdentityContext = createContext<Identity | null>(null);

export function useSelectedIdentity() {
  return useContext(IdentityContext);
}
