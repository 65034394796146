import { AbilityBuilder, PureAbility } from "@casl/ability";
import { PrismaQuery, Subjects, createPrismaAbility } from "@casl/prisma";

import { Account, Admin, AdminAction, PiiAuditLog } from "@atlas/prisma/client";

type Actions = "read" | "update" | "create" | "delete" | "soft-delete" | "assume-identity";

export type AppAbility = PureAbility<
  [
    Actions,
    (
      | "all"
      | "Accounts Tab"
      | "Account Details"
      | "Data Brokers Tab"
      | "Dark Web Tab"
      | "Roster Tab"
      | "Family Details"
      | "Atlasmail"
      | "Enrollment"
      | "Data Integrity"
      | "Takedown Jobs"
      | "Template Tab"
      | "Waitlist Tab"
      | "Feature Flags"
      | Subjects<{
          Account: Account;
          AdminAction: AdminAction;
          PiiAuditLog: PiiAuditLog;
        }>
    ),
  ],
  PrismaQuery
>;
type Roles = "opsteam" | "manager" | "admin";

export function getAdminAbility(admin: Admin): AppAbility {
  const { can, build } = new AbilityBuilder<AppAbility>(createPrismaAbility);

  switch (admin.role as Roles) {
    case "opsteam":
      can("read", "Data Brokers Tab");
      break;
    case "manager":
      can("read", "all");
      can("update", "all");
      can("create", "all");
      break;
    case "admin":
      can("read", "all");
      can("update", "all");
      can("create", "all");
      can("delete", "all");
      can("soft-delete", "all");
      can("assume-identity", "Account");
      break;
    default:
      break;
  }

  return build();
}
