import Link from "next/link";
import { useContext } from "react";

import { config } from "@atlas/app-config";
import { Icon } from "@atlas/ui/Icon";

import { useFeatureFlag } from "../../context/statsig";
import { useCurrentUser } from "../../context/user";

import { ControlledDrawer, DrawerController } from "./DrawController";
import { Menus } from "./Menus";
import { ReferralLink } from "./ReferralLink";
import { NewUserNavbarMenus } from "./UserNavbar";

const HamburgerButton = () => {
  const drawerControl = useContext(DrawerController);
  return (
    <button
      onClick={() => {
        drawerControl.set?.({
          open: true,
          anchor: "left",
          label: "Menu",
          items: NewUserNavbarMenus,
        });
      }}
    >
      <Icon icon="Hamburger" className="size-6" />
    </button>
  );
};

export const TopNav = () => {
  const { value: newLeftNav } = useFeatureFlag("new_left_nav");
  const user = useCurrentUser();

  if (newLeftNav)
    return (
      <div className="h-16 w-full shrink-0 items-center justify-between border-b bg-white px-4 sm:px-8 md:hidden">
        <div className="flex h-full items-center justify-between">
          <ControlledDrawer user={user}>
            <HamburgerButton />
          </ControlledDrawer>

          <Link href={config.user_home_path} className="hover:opacity-80">
            <img src="/img/logo_atlas.png" className="h-6 w-auto" />
          </Link>
          <Icon icon="Support" className="size-6" />
        </div>
      </div>
    );

  return (
    <div className="flex h-20 w-full shrink-0 items-center justify-between border-b bg-white px-4 sm:px-8">
      <Link href={config.user_home_path} className="hover:opacity-80">
        <Icon icon="logo" size="no-size" />
      </Link>
      <ReferralLink className="px-4 text-center" />
      <ControlledDrawer user={user}>
        <div className="flex items-center md:hidden">
          <Menus menus={["Support", "MobileAccount"]} user={user} mobile topNav />
        </div>
      </ControlledDrawer>
      <Menus
        menus={["Support", "Logout"]}
        desktop
        topNav
        user={user}
        className="text-primary-dark mt-0 hidden flex-row pl-2 md:flex"
      />
    </div>
  );
};
