import nameCase from "namecase";

export const isJson = (json) => {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
};

export function capitalizeFirstLetter(str?: string | null) {
  if (!str || !str.length) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Intelligently capitalizes a person's name, handling edge
 * cases like "MacDonald" and "O'Brien"
 */
export function capitalizePersonName(name?: string | null) {
  if (!name || !name.length) return "";
  return nameCase(name, { individualFields: true });
}

export function percentStringFromDecimal(num: number) {
  return `${(num * 100).toFixed(1)}%`;
}

export async function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function chunkArray<T>(array: T[], size: number): T[][] {
  return array.reduce((acc, _, i) => {
    if (i % size === 0) {
      acc.push(array.slice(i, i + size));
    }
    return acc;
  }, [] as T[][]);
}

export function enumerateString(words) {
  if (words.length === 0) return "";
  if (words.length === 1) return words[0];
  return words.slice(0, -1).join(", ") + " and " + words.slice(-1);
}
