import z from "zod";
// GENERATE CODE BELOW USING `cd cli && pnpm start brokers`

export const Brokers: BrokerMapProps = {
  "411": {
    url: "411.com",
    label: "Whitepages",
    deprecated: true,
    covered: {},
  },
  "33mileradius_com": {
    url: "33mileradius.com",
    label: "33 Mile Radius",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "360mediadirect_com": {
    url: "360mediadirect.com",
    label: "360 Media Direct",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  "411_info": {
    url: "411.info",
    label: "411.info",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "411fcc_com": {
    url: "411fcc.com",
    label: "411 FCC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  _411locate: {
    url: "411locate.com",
    label: "411 Locate.com",
    deprecated: true,
    covered: {},
  },
  "6sense_com": {
    url: "6sense.com",
    label: "6Sense",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "700credit_com": {
    url: "700credit.com",
    label: "700Credit",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "8451_com": {
    url: "8451.com",
    label: "8451",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  abdata_com: {
    url: "abdata.com",
    label: "A.B. Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  absolute_people_search: {
    url: "absolutepeoplesearch.com",
    label: "Absolute People Search",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  acbj_com: {
    url: "acbj.com",
    label: "American City Business Journals",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  accenture_com: {
    url: "accenture.com",
    label: "Accenture",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  accudata_com: {
    url: "accudata.com",
    label: "AccuData",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  acculeads_com: {
    url: "acculeads.com",
    label: "AccuLeads",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  accurate_com: {
    url: "accurate.com",
    label: "Accurate Background",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  accurateappend_com: {
    url: "accurateappend.com",
    label: "Accurate Append",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  accusourcehr_com: {
    url: "accusourcehr.com",
    label: "AccuSourceHR",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  accutrend_com: {
    url: "accutrend.com",
    label: "Accutrend",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  accuzip_com: {
    url: "accuzip.com",
    label: "AccuZIP",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  achcoop_com: {
    url: "achcoop.com",
    label: "ACH, Address Clearing House",
    deprecated: true,
    covered: {},
  },
  acquiretm_com: {
    url: "acquiretm.com",
    label: "Acquire TM",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  acuant_com: {
    url: "acuant.com",
    label: "Acuant",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  acutraq_com: {
    url: "acutraq.com",
    label: "Acutraq Background Screening",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  acxiom_com: {
    url: "acxiom.com",
    label: "Acxiom",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  adadapted_com: {
    url: "adadapted.com",
    label: "AdAdapted",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  addirectinc_com: {
    url: "addirectinc.com",
    label: "Ad Direct",
    deprecated: true,
    covered: {},
  },
  addresses: {
    url: "addresses.com",
    label: "PeopleConnect, Inc.",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  adpselect_com: {
    url: "adpselect.com",
    label: "ADP",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  adstradata_com: {
    url: "adstradata.com",
    label: "Adstra",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  advanced_people_search: {
    url: "advanced-people-search.com",
    label: "Advanced People Search",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  advanced_background_checks: {
    url: "advancedbackgroundchecks.com",
    label: "Advancedbackgroundchecks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  advcredit_com: {
    url: "advcredit.com",
    label: "Advantage Credit",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  aescass_com: {
    url: "aescass.com",
    label: "AES Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  affinity_solutions: {
    url: "affinity.solutions",
    label: "Affinity Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  affinityanswers_com: {
    url: "affinityanswers.com",
    label: "Affinity Answers Corporation",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  agentjet_com: {
    url: "agentjet.com",
    label: "AgentJet",
    deprecated: true,
    covered: {},
  },
  agrgroupinc_com: {
    url: "agrgroupinc.com",
    label: "All Global Resources",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  agrmarketingsolutions_com: {
    url: "agrmarketingsolutions.com",
    label: "AGR Marketing Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  aidentified_com: {
    url: "aidentified.com",
    label: "Aidentified",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  alesco_com: {
    url: "alesco.com",
    label: "The Alesco Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
  },
  alescodata_com: {
    url: "alescodata.com",
    label: "Alesco Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  allareacodes: {
    url: "allareacodes.com",
    label: "Allareacodes",
    deprecated: true,
    covered: {},
  },
  allbiz_com: {
    url: "allbiz.com",
    label: "AllBiz",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  alliantinsight_com: {
    url: "alliantinsight.com",
    label: "Alliant Cooperative Data Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  allpeople_com: {
    url: "allpeople.com",
    label: "Allpeople",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  allwebleads_com: {
    url: "allwebleads.com",
    label: "All Web Leads",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  alphamoon_ai: {
    url: "alphamoon.ai",
    label: "AlphaMoon.ai",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  alphonso_tv: {
    url: "alphonso.tv",
    label: "Alphonso",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  alticeusa_com: {
    url: "alticeusa.com",
    label: "Altice USA",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  altisource_com: {
    url: "altisource.com",
    label: "Altisource Holdings",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  altosresearch_com: {
    url: "altosresearch.com",
    label: "Altos Research",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  altrata_com: {
    url: "altrata.com",
    label: "Altrata",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  altusgroup_com: {
    url: "altusgroup.com",
    label: "Altus Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ameribase_com: {
    url: "ameribase.com",
    label: "Ameribase",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  america_phone_book: {
    url: "americaphonebook.com",
    label: "America Phone Book",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  amerilist_com: {
    url: "amerilist.com",
    label: "Amerilist",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  amplemarket_com: {
    url: "amplemarket.com",
    label: "Amplemarket",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  amrent_com: {
    url: "amrent.com",
    label: "AmRent",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  amsive_com: {
    url: "amsive.com",
    label: "Amsive",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "analytics-iq_com": {
    url: "analytics-iq.com",
    label: "AnalyticsIQ, Inc.",
    deprecated: true,
    covered: {},
  },
  analytics_ag: {
    url: "analytics.ag",
    label: "Ag-Analytics",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  analyticsiq_com: {
    url: "analyticsiq.com",
    label: "AnalyticsIQ",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ancestry_com: {
    url: "ancestry.com",
    label: "Ancestry",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  anchorcomputer_com: {
    url: "anchorcomputer.com",
    label: "Anchor Computer",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  anchorcomputersoftware_com: {
    url: "anchorcomputersoftware.com",
    label: "Anchor Software",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  andrewswharton_com: {
    url: "andrewswharton.com",
    label: "Andrews Wharton",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  anomali_com: {
    url: "anomali.com",
    label: "Anomali",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  anteriad_com: {
    url: "anteriad.com",
    label: "Anteriad",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  anthropic_com: {
    url: "anthropic.com",
    label: "Anthropic",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  anymailfinder_com: {
    url: "anymailfinder.com",
    label: "AMF Internet Services",
    deprecated: true,
    covered: {},
  },
  anyscale_com: {
    url: "anyscale.com",
    label: "AnyScale",
    deprecated: true,
    covered: {},
  },
  anywho: {
    url: "anywho.com",
    label: "Y",
    deprecated: true,
    covered: {},
  },
  apeiron_io: {
    url: "apeiron.io",
    label: "Apeiron",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  apexanalytix_com: {
    url: "apexanalytix.com",
    label: "Apex Analytix",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  apollo_io: {
    url: "apollo.io",
    label: "Apollo.io",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  apollointeractive_com: {
    url: "apollointeractive.com",
    label: "Apollo Interactive",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  appfolio_com: {
    url: "appfolio.com",
    label: "AppFolio",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  applecart_co: {
    url: "applecart.co",
    label: "Project Applecart",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  appsci_io: {
    url: "appsci.io",
    label: "AppScience",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  aristotle_com: {
    url: "aristotle.com",
    label: "Aristotle",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  arity_com: {
    url: "arity.com",
    label: "Arity",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  arivify: {
    url: "arivify.com",
    label: "Arivify",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  arrest_facts: {
    url: "arrestfacts.com",
    label: "Arrestfacts",
    deprecated: true,
    covered: {},
  },
  "ars-consumeroffice_com": {
    url: "ars-consumeroffice.com",
    label: "Advanced Resolution Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  arthur_ai: {
    url: "arthur.ai",
    label: "Arthur AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  askcertegy_com: {
    url: "askcertegy.com",
    label: "Certegy Payment Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  aslmarketing_com: {
    url: "aslmarketing.com",
    label: "ASL Marketing",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  asurint_com: {
    url: "asurint.com",
    label: "Asurint",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  atdata_com: {
    url: "atdata.com",
    label: "AtData",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  atriks_com: {
    url: "atriks.com",
    label: "Atriks",
    deprecated: true,
    covered: {},
  },
  att_com: {
    url: "att.com",
    label: "AT&T",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  attomdata_com: {
    url: "attomdata.com",
    label: "Attom",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  audienceacuity_com: {
    url: "audienceacuity.com",
    label: "Audience Acuity",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  authentic8_com: {
    url: "authentic8.com",
    label: "Authentic8",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  authenticom_com: {
    url: "authenticom.com",
    label: "Authenticom",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  automotivemastermind_com: {
    url: "automotivemastermind.com",
    label: "Automotive Mastermind",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  axur_com: {
    url: "axur.com",
    label: "Axur",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  babelstreet_com: {
    url: "babelstreet.com",
    label: "Babel Street",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  background_alert: {
    url: "backgroundalert.com",
    label: "Background Alert",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePostalAddress: true,
  },
  background_check_run: {
    url: "backgroundcheck.run",
    label: "Backgroundcheck",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  background_checkers: {
    url: "backgroundcheckers.net",
    label: "Backgroundcheckers",
    deprecated: true,
    covered: {},
  },
  backgroundchecks_com: {
    url: "backgroundchecks.com",
    label: "backgroundchecks.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  backgroundrecords_com: {
    url: "backgroundrecords.com",
    label: "Backgroundrecords",
    deprecated: true,
    covered: {},
  },
  backgroundsonline_com: {
    url: "backgroundsonline.com",
    label: "IQ Data Systems, Inc. dba Backgrounds Online",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  bain_com: {
    url: "bain.com",
    label: "Bain & Company",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  batchleads_io: {
    url: "batchservice.com",
    label: "BatchService",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  batchskiptracing_com: {
    url: "batchskiptracing.com",
    label: "Batch Skip Tracing",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  bbdirect_com: {
    url: "bbdirect.com",
    label: "BB Direct",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  bccsoftware_com: {
    url: "bccsoftware.com",
    label: "BCC Software",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  bdo_com: {
    url: "bdo.com",
    label: "BDO",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  been_verified: {
    url: "beenverified.com",
    label: "BeenVerified",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  belardiwong_com: {
    url: "belardiwong.com",
    label: "Belardi Wong",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
  },
  belleslink_com: {
    url: "belleslink.com",
    label: "Belles Link",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  bigdbm_com: {
    url: "bigdbm.com",
    label: "BIGDBM",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  bigreport_com: {
    url: "bigreport.com",
    label: "Business Information Group, Inc.",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  bizapedia_com: {
    url: "bizapedia.com",
    label: "Bizapedia",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  blackbaud_com: {
    url: "blackbaud.com",
    label: "Blackbaud",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  blackknightinc_com: {
    url: "blackknightinc.com",
    label: "Black Knight",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  blis_com: {
    url: "blis.com",
    label: "Blis Global",
    deprecated: true,
    covered: {},
  },
  blisspointmedia_com: {
    url: "blisspointmedia.com",
    label: "Bliss Point Media",
    deprecated: true,
    covered: {},
  },
  blockshopper_com: {
    url: "blockshopper.com",
    label: "Blockshopper",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  boardex_com: {
    url: "boardex.com",
    label: "Boardex",
    deprecated: true,
    covered: {},
  },
  boardroominsiders_com: {
    url: "boardroominsiders.com",
    label: "Boardroom Insiders",
    deprecated: true,
    covered: {},
  },
  bolddata_nl: {
    url: "bolddata.nl",
    label: "BoldData",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  bookyourdata_com: {
    url: "bookyourdata.com",
    label: "Book Your Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  boomtownroi_com: {
    url: "boomtownroi.com",
    label: "BoomTown",
    deprecated: true,
    covered: {},
  },
  boundarysolutions_com: {
    url: "boundarysolutions.com",
    label: "Boundary Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  brandefense_com: {
    url: "brandefense.com",
    label: "Brandefense",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  brandwatch_com: {
    url: "brandwatch.com",
    label: "Brandwatch",
    deprecated: true,
    covered: {},
  },
  breachdirectory_org: {
    url: "breachdirectory.org",
    label: "BreachDirectory",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  breezy_hr: {
    url: "breezy.hr",
    label: "Breezy HR",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  bridg_com: {
    url: "bridg.com",
    label: "Bridg",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  brightspeed_com: {
    url: "brightspeed.com",
    label: "Brightspeed",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  broadridge_com: {
    url: "broadridge.com",
    label: "Broadridge Financial Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  brooksim_com: {
    url: "brooksim.com",
    label: "Brooks Integrated Marketing",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  buildertrend_com: {
    url: "buildertrend.com",
    label: "Buildertrend Solutions",
    deprecated: true,
    covered: {},
  },
  buildout_com: {
    url: "buildout.com",
    label: "Buildout",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  bullhorn_com: {
    url: "bullhorn.com",
    label: "Bullhorn",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  bumper_com: {
    url: "bumper.com",
    label: "Bumper",
    deprecated: true,
    covered: {},
  },
  buyerlink_com: {
    url: "buyerlink.com",
    label: "Buyerlink",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  buzzfile_com: {
    url: "buzzfile.com",
    label: "Buzzfile Media LLC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  c3_ai: {
    url: "c3.ai",
    label: "C3 AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cactusmailing_com: {
    url: "cactusmailing.com",
    label: "Cactus Mailing Company",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
  },
  cadent_tv: {
    url: "cadent.tv",
    label: "Cadent",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  calibrant_com: {
    url: "calibrant.com",
    label: "Calibrant Digital",
    deprecated: true,
    covered: {},
  },
  calleridtest_com: {
    url: "calleridtest.com",
    label: "CallerID Test",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  callersmart_com: {
    url: "callersmart.com",
    label: "Caller Smart",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  call_truth: {
    url: "calltruth.com",
    label: "Connect Computer",
    deprecated: true,
    covered: {},
  },
  caltopo_com: {
    url: "caltopo.com",
    label: "CalTopo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  capgemini_com: {
    url: "capgemini.com",
    label: "Capgemini",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cardlytics_com: {
    url: "cardlytics.com",
    label: "Cardlytics",
    deprecated: true,
    covered: {},
  },
  careerbuilder_com: {
    url: "careerbuilder.com",
    label: "CareerBuilder",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  carejourney_com: {
    url: "carejourney.com",
    label: "CareJourney",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  carneydirect_com: {
    url: "carneydirect.com",
    label: "Carney Direct Marketing",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  carpe_io: {
    url: "carpe.io",
    label: "Carpe Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  carto_com: {
    url: "carto.com",
    label: "CARTO",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  catalina_com: {
    url: "catalina.com",
    label: "Catalina",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  cathedralcorporation_com: {
    url: "cathedralcorporation.com",
    label: "Cathedral",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cccverify_com: {
    url: "cccverify.com",
    label: "CCC Verify",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cdkglobal_com: {
    url: "cdkglobal.com",
    label: "CDK Global",
    deprecated: true,
    covered: {},
  },
  "cds-global_com": {
    url: "cds-global.com",
    label: "CDS Global",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cellrevealer: {
    url: "cellrevealer.com",
    label: "Cell Revealer",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  censia_com: {
    url: "censia.com",
    label: "Censia",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  censys_com: {
    url: "censys.com",
    label: "Censys",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  centeda: {
    url: "Centeda.com",
    label: "Centeda",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
  },
  centurydirect_net: {
    url: "centurydirect.net",
    label: "Century Direct",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  centurylink_com: {
    url: "centurylink.com",
    label: "CenturyLink",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cerebras_net: {
    url: "cerebras.net",
    label: "Cerebras",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  certiphi_com: {
    url: "certiphi.com",
    label: "Certiphi",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  certn_co: {
    url: "certn.co",
    label: "Certn",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  character_ai: {
    url: "character.ai",
    label: "Character.ai",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  charter_com: {
    url: "charter.com",
    label: "Charter Communications",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  check_people: {
    url: "CheckPeople.com",
    label: "CheckPeople",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  checkr_com: {
    url: "checkr.com",
    label: "Checkr",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  checksecrets: {
    url: "checksecrets.com",
    label: "Truth Now LLC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
  },
  check_them: {
    url: "checkthem.com",
    label: "Checkthem",
    deprecated: true,
    covered: {},
  },
  cherre_com: {
    url: "cherre.com",
    label: "Cherre",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  chexsystems_com: {
    url: "chexsystems.com",
    label: "ChexSystems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
  },
  choreograph_com: {
    url: "choreograph.com",
    label: "Choreograph",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ciccredit_com: {
    url: "ciccredit.com",
    label: "CIC Mortgage Credit",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cicreports_com: {
    url: "cicreports.com",
    label: "Contemporary Information Corp",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cincpro_com: {
    url: "cincpro.com",
    label: "Cinc",
    deprecated: true,
    covered: {},
  },
  cint_com: {
    url: "cint.com",
    label: "Cint",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cisco_com: {
    url: "cisco.com",
    label: "Cisco",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cision_com: {
    url: "cision.com",
    label: "Cision",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cisive_com: {
    url: "cisive.com",
    label: "Cisive",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  citydata_ai: {
    url: "citydata.ai",
    label: "Citydata",
    deprecated: true,
    covered: {},
  },
  citysnap_com: {
    url: "citysnap.com",
    label: "CitySnap",
    deprecated: true,
    covered: {},
  },
  civisanalytics_com: {
    url: "civisanalytics.com",
    label: "Civis Analytics,",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  civitech_io: {
    url: "civitech.io",
    label: "Civitech",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  claritas_com: {
    url: "claritas.com",
    label: "Claritas",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  clarityservices_com: {
    url: "clarityservices.com",
    label: "Clarity Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  classfinders_com: {
    url: "ClassFinders.com",
    label: "Classfinders",
    deprecated: true,
    covered: {},
  },
  classmates_com: {
    url: "classmates.com",
    label: "Classmates",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  clearbit_com: {
    url: "clearbit.com",
    label: "Clearbit",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cleargage_com: {
    url: "cleargage.com",
    label: "ClearGage",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  clearskip_com: {
    url: "clearskip.com",
    label: "Clear Skip Company",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  clearstar_net: {
    url: "clearstar.net",
    label: "ClearStar",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  clearview_ai: {
    url: "clearview.ai",
    label: "Clearview AI",
    deprecated: true,
    covered: {},
  },
  clickagy_com: {
    url: "clickagy.com",
    label: "Clickagy",
    deprecated: true,
    covered: {},
  },
  clicksearch_us: {
    url: "clicksearch.us",
    label: "Clicksearch",
    deprecated: true,
    covered: {},
  },
  cloze_com: {
    url: "cloze.com",
    label: "Cloze",
    deprecated: true,
    covered: {},
  },
  clustrmaps: {
    url: "clustrmaps.com",
    label: "Clustrmaps",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  cobwebs_com: {
    url: "cobwebs.com",
    label: "Cobwebs",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cocofinder: {
    url: "cocofinder.com",
    label: "Cocofinder",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  cognism_com: {
    url: "cognism.com",
    label: "Cognism",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cohere_com: {
    url: "cohere.com",
    label: "Cohere",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  coleinformation_com: {
    url: "coleinformation.com",
    label: "Cole Information Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  comcast_com: {
    url: "comcast.com",
    label: "Comcast",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  compactlists_com: {
    url: "compactlists.com",
    label: "Compact Information Systems",
    deprecated: true,
    covered: {},
  },
  compile_com: {
    url: "compile.com",
    label: "Compile",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  completemailinglists_com: {
    url: "completemailinglists.com",
    label: "Complete Mailing Lists",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  completemedicallists_com: {
    url: "completemedicallists.com",
    label: "Complete Medical Lists",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  compstak_com: {
    url: "compstak.com",
    label: "CompStak",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  computershare_com: {
    url: "computershare.com",
    label: "Computershare",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  comscore_com: {
    url: "comscore.com",
    label: "Comscore",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  connectedinvestors_com: {
    url: "connectedinvestors.com",
    label: "Connected Investors",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  connextdigital_com: {
    url: "connextdigital.com",
    label: "Connext Digital",
    deprecated: true,
    covered: {},
  },
  consolidatedknowledge_com: {
    url: "consolidatedknowledge.com",
    label: "Consolidated Knowledge",
    deprecated: true,
    covered: {},
  },
  constella_com: {
    url: "constella.com",
    label: "Constella Intelligence",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  constellation1_com: {
    url: "constellation1.com",
    label: "Constellation1",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  constellationreg_com: {
    url: "constellationreg.com",
    label: "Constellation Real Estate Group",
    deprecated: true,
    covered: {},
  },
  constellationws_com: {
    url: "constellationws.com",
    label: "Constellation Web Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
  },
  contactout_com: {
    url: "contactout.com",
    label: "ContactOut",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  convergentusa_com: {
    url: "convergentusa.com",
    label: "ConvergentUSA",
    deprecated: true,
    covered: {},
  },
  convex_com: {
    url: "convex.com",
    label: "Convex Labs",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  corelogic_com: {
    url: "corelogic.com",
    label: "CoreLogic",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  coresignal_com: {
    url: "coresignal.com",
    label: "Core Signal",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  corporation_wiki: {
    url: "corporationwiki.com",
    label: "Corporation Wiki",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  corragroup_com: {
    url: "corragroup.com",
    label: "Corra Group",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  costar_com: {
    url: "costar.com",
    label: "CoStar Realty Information",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  courtcasefinder: {
    url: "courtcasefinder.com",
    label: "InfoPay",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  creditinfosystems_com: {
    url: "creditinfosystems.com",
    label: "Credit Information Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  crexi_com: {
    url: "crexi.com",
    label: "Crexi",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  criminalregistry: {
    url: "criminalregistry.org",
    label: "criminalregistry.org",
    deprecated: true,
    covered: {},
  },
  "cross-check_com": {
    url: "cross-check.com",
    label: "CrossCheck",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  crosspixel_net: {
    url: "crosspixel.net",
    label: "Cross Pixel Media",
    deprecated: true,
    covered: {},
  },
  crowdstrike_com: {
    url: "crowdstrike.com",
    label: "CrowdStrike",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  crunchbase_com: {
    url: "crunchbase.com",
    label: "Crunchbase",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  csgsystems_com: {
    url: "csgsystems.com",
    label: "CSG International",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cuebiq_com: {
    url: "cuebiq.com",
    label: "Cuebiq",
    deprecated: true,
    covered: {},
  },
  customer_com: {
    url: "customer.com",
    label: "Customer Communications Group",
    deprecated: true,
    covered: {},
  },
  cybba_com: {
    url: "cybba.com",
    label: "Cybba",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cyberbackgroundchecks: {
    url: "cyberbackgroundchecks.com",
    label: "Cyber Background Checks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  cyberint_com: {
    url: "cyberint.com",
    label: "Cyberint",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cybersixgill_com: {
    url: "cybersixgill.com",
    label: "Cybersixgill",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cybersyn_com: {
    url: "cybersyn.com",
    label: "Cybersyn",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cyble_com: {
    url: "cyble.com",
    label: "Cyble",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cyndx_com: {
    url: "cyndx.com",
    label: "Cyndx Networks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  darkowl_com: {
    url: "darkowl.com",
    label: "DarkOwl",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  darkscope_com: {
    url: "darkscope.com",
    label: "Darkscope",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dashlane_com: {
    url: "dashlane.com",
    label: "Dashlane",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "data-axle_com": {
    url: "data-axle.com",
    label: "Data Axle",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "data-mail_com": {
    url: "data-mail.com",
    label: "Data-Mail",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "data-management_com": {
    url: "data-management.com",
    label: "Data Management",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dataaxlenonprofit_com: {
    url: "dataaxlenonprofit.com",
    label: "Data Axle Nonprofit",
    deprecated: true,
    covered: {},
  },
  databaseusa_com: {
    url: "databaseusa.com",
    label: "DatabaseUSA",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  databricks_com: {
    url: "databricks.com",
    label: "Databricks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  databridgemarketing_com: {
    url: "databridgemarketing.com",
    label: "DataBridge Marketing Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datacaptive_com: {
    url: "datacaptive.com",
    label: "Data Captive",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datadelivers_com: {
    url: "datadelivers.com",
    label: "DataDelivers",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datafacts_com: {
    url: "datafacts.com",
    label: "Data Facts",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datafinder_com: {
    url: "datafinder.com",
    label: "Datafinder",
    deprecated: true,
    covered: {},
  },
  datagma_com: {
    url: "datagma.com",
    label: "Datagma",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  datalabusa_com: {
    url: "datalabusa.com",
    label: "Datalab",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datalinedata_com: {
    url: "datalinedata.com",
    label: "Dataline",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datamangroup_com: {
    url: "datamangroup.com",
    label: "Dataman Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  datamasters_org: {
    url: "datamasters.org",
    label: "Data Masters",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datamatx_com: {
    url: "datamatx.com",
    label: "DATAMATX",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datanyze_com: {
    url: "datanyze.com",
    label: "Datanyze",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  datapartners_com: {
    url: "datapartners.com",
    label: "Data Partners",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datarade_ai: {
    url: "datarade.ai",
    label: "Datarade",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datascoutllc_com: {
    url: "datascoutllc.com",
    label: "DataScout",
    deprecated: true,
    covered: {},
  },
  datascreening_com: {
    url: "datascreening.com",
    label: "Data Screening",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  datastreamgroup_com: {
    url: "datastreamgroup.com",
    label: "Datastream Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datasys_com: {
    url: "datasys.com",
    label: "Datasys Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dataveria: {
    url: "dataveria.com",
    label: "Dataveria",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePostalAddress: true,
  },
  dataverify_com: {
    url: "dataverify.com",
    label: "DataVerify",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dataxltd_com: {
    url: "dataxltd.com",
    label: "DataX",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datazapp_com: {
    url: "datazapp.com",
    label: "Datazapp",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  datonics_com: {
    url: "datonics.com",
    label: "Datonics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dealcheck_io: {
    url: "dealcheck.io",
    label: "DealCheck",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  dealerx_com: {
    url: "dealerx.com",
    label: "DealerX",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dealmachine_com: {
    url: "dealmachine.com",
    label: "DealMachine",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  decide_co: {
    url: "decide.co",
    label: "Decide Technologies",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  decisionlinks_com: {
    url: "decisionlinks.com",
    label: "DecisionLinks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  deeprootanalytics_com: {
    url: "deeprootanalytics.com",
    label: "Deep Root Analytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  deepsync_com: {
    url: "deepsync.com",
    label: "Deep Sync",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  defendify_com: {
    url: "defendify.com",
    label: "Defendify",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  definitivehc_com: {
    url: "definitivehc.com",
    label: "Definitive Healthcare",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dehashed_com: {
    url: "dehashed.com",
    label: "DeHashed",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  deloitte_com: {
    url: "deloitte.com",
    label: "Deloitte",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  deluxe_com: {
    url: "deluxe.com",
    label: "Deluxe Corporation",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  delvepoint_com: {
    url: "delvepoint.com",
    label: "Delvepoint",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  demandbase_com: {
    url: "demandbase.com",
    label: "Demandbase",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  demandscience_com: {
    url: "demandscience.com",
    label: "Demand Science Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  demyst_com: {
    url: "demyst.com",
    label: "DemystData",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dentsu_com: {
    url: "dentsu.com",
    label: "Dentsu",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dexknows: {
    url: "dexknows.com",
    label: "Dexknows",
    deprecated: true,
    covered: {},
  },
  dice_com: {
    url: "dice.com",
    label: "Dice Career Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  digdevdirect_com: {
    url: "digdevdirect.com",
    label: "Digdevdirect",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  digitalmediasolutions_com: {
    url: "digitalmediasolutions.com",
    label: "Digital Media Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  digitalsegment_com: {
    url: "digitalsegment.com",
    label: "Digital Segment",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  digitalshadows_com: {
    url: "digitalshadows.com",
    label: "Digital Shadows",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  diligent_com: {
    url: "diligent.com",
    label: "Diligent Corporation",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  directmail_com: {
    url: "directmail.com",
    label: "DM Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  disa_com: {
    url: "disa.com",
    label: "DISA Global Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  disconetwork_com: {
    url: "disconetwork.com",
    label: "Disco Technology",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  discoverorg_com: {
    url: "discoverorg.com",
    label: "DiscoverOrg Data",
    deprecated: true,
    covered: {},
  },
  discoverydata_com: {
    url: "discoverydata.com",
    label: "Discovery Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dish_com: {
    url: "dish.com",
    label: "Dish Wireless",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  disqus_com: {
    url: "disqus.com",
    label: "Disqus",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  district4labs_com: {
    url: "district4labs.com",
    label: "District 4",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  diversesolutions_com: {
    url: "diversesolutions.com",
    label: "Market Leader Group",
    deprecated: true,
    covered: {},
  },
  dmdatabases_com: {
    url: "dmdatabases.com",
    label: "DMDatabases",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dnb_com: {
    url: "dnb.com",
    label: "Dun & Bradstreet",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dobsearch_com: {
    url: "dobsearch.com",
    label: "D.O.B. Search",
    deprecated: true,
    covered: {},
  },
  domaintools_com: {
    url: "domaintools.com",
    label: "DomainTools",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dowjones_com: {
    url: "dowjones.com",
    label: "Dow Jones",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  doxim_com: {
    url: "doxim.com",
    label: "Doxim",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dragos_com: {
    url: "dragos.com",
    label: "Dragos",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  driveniq_com: {
    url: "driveniq.com",
    label: "DrivenIQ",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  drivershistory_com: {
    url: "drivershistory.com",
    label: "Datalink",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dstillery_com: {
    url: "dstillery.com",
    label: "Dstillery",
    deprecated: true,
    covered: {},
  },
  dtn_com: {
    url: "dtn.com",
    label: "DTN",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dxc_com: {
    url: "dxc.com",
    label: "DXC Technology",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  dynata_com: {
    url: "dynata.com",
    label: "Dynata",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  cappex_com: {
    url: "eab.com",
    label: "EAB Global",
    deprecated: true,
    covered: {},
  },
  eab_com: {
    url: "eab.com",
    label: "EAB Global",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  earlywarning_com: {
    url: "earlywarning.com",
    label: "Early Warning Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  easy_background_checks: {
    url: "easybackgroundchecks.com",
    label: "Easybackgroundchecks",
    deprecated: true,
    covered: {},
  },
  easyemerge_com: {
    url: "easyemerge.com",
    label: "Elm Street Technology",
    deprecated: true,
    covered: {},
  },
  ecopiatech_com: {
    url: "ecopiatech.com",
    label: "Ecopia AI",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  edvisors_com: {
    url: "edvisors.com",
    label: "Edvisors Network",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ekata_com: {
    url: "ekata.com",
    label: "Ekata",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  elmstreet_com: {
    url: "elmstreet.com",
    label: "Elm Street Technology",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
  },
  eltoro_com: {
    url: "eltoro.com",
    label: "El Toro",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  emailindustries_com: {
    url: "emailindustries.com",
    label: "Email Industries",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  emailtracer_com: {
    url: "emailtracer.com",
    label: "Emailtracer",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  emerges_com: {
    url: "emerges.com",
    label: "eMerges.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  empinfo_com: {
    url: "empinfo.com",
    label: "EmpInfo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  enformion_com: {
    url: "enformion.com",
    label: "Enformion",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  enigma_com: {
    url: "enigma.com",
    label: "Enigma",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  enspireforenterprise_com: {
    url: "enspireforenterprise.com",
    label: "Enspire for Enterprise",
    deprecated: true,
    covered: {},
  },
  entelo_com: {
    url: "entelo.com",
    label: "Entelo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  epsilon_com: {
    url: "epsilon.com",
    label: "Epsilon",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  equifax_com: {
    url: "equifax.com",
    label: "Equifax",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  erepublic_com: {
    url: "erepublic.com",
    label: "e.Republic",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  esri_com: {
    url: "esri.com",
    label: "Esri",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  evs7_com: {
    url: "evs7.com",
    label: "Electronic Voice Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  exactcustomer_com: {
    url: "exactcustomer.com",
    label: "Exact Customer",
    deprecated: true,
    covered: {},
  },
  exactdata_com: {
    url: "exactdata.com",
    label: "Exact Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  exlservice_com: {
    url: "exlservice.com",
    label: "EXL Service Holdings",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  experian_com: {
    url: "experian.com",
    label: "Experian",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  factualdata_com: {
    url: "factualdata.com",
    label: "Factual Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fadv_com: {
    url: "fadv.com",
    label: "First Advantage Corporation",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  familysearch_org: {
    url: "familysearch.org",
    label: "Familysearch",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  familytreenow_com: {
    url: "familytreenow.com",
    label: "FamilyTreeNow",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  famousbirthdays_com: {
    url: "famousbirthdays.com",
    label: "Famous Birthdays",
    deprecated: true,
    covered: {},
  },
  faraday_io: {
    url: "faraday.io",
    label: "Faraday",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fastbackgroundcheck: {
    url: "fastbackgroundcheck.com",
    label: "Fastbackgroundcheck",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  fast_people_search: {
    url: "fastpeoplesearch.com",
    label: "FastPeopleSearch",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  fetcher_ai: {
    url: "fetcher.ai",
    label: "Fetcher",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  fico_com: {
    url: "fico.com",
    label: "Fair Issac Corp.",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fiddler_ai: {
    url: "fiddler.ai",
    label: "Fiddler AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fifty_io: {
    url: "fifty.io",
    label: "Fifty Technology",
    deprecated: true,
    covered: {},
  },
  findem_ai: {
    url: "findem.ai",
    label: "Findem",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  findpeoplefast_net: {
    url: "findpeoplefast.net",
    label: "Find People Fast",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  find_people_search: {
    url: "findpeoplesearch.com",
    label: "Findpeoplesearch",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  findtrueowner_com: {
    url: "findtrueowner.com",
    label: "FindTrueOwner",
    deprecated: true,
    covered: {},
  },
  finpay_com: {
    url: "finpay.com",
    label: "FinPay",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  finthrive_com: {
    url: "finthrive.com",
    label: "FinThrive",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  firefox_com: {
    url: "firefox.com",
    label: "Firefox",
    deprecated: true,
    covered: {},
  },
  first_io: {
    url: "first.io",
    label: "First Leads",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  firstam_com: {
    url: "firstam.com",
    label: "First American",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  firstdirectmarketing_com: {
    url: "firstdirectmarketing.com",
    label: "First Direct",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  firstorion_com: {
    url: "firstorion.com",
    label: "First Orion",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fisglobal_com: {
    url: "fisglobal.com",
    label: "FIS",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  flashpoint_io: {
    url: "flashpoint.io",
    label: "Flashpoint Intelligence",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  floridaresidentsdirectory_com: {
    url: "floridaresidentsdirectory.com",
    label: "Floridaresidentsdirectory",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fluentco_com: {
    url: "fluentco.com",
    label: "Fluent",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fmadata_com: {
    url: "fmadata.com",
    label: "First Movers Advantage",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  "focus-usa_com": {
    url: "focus-usa.com",
    label: "Focus USA",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  foleyservices_com: {
    url: "foleyservices.com",
    label: "Foley",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  forddirect_com: {
    url: "forddirect.com",
    label: "FordDirect",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  forewarn_com: {
    url: "forewarn.com",
    label: "Forewarn",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fortinet_com: {
    url: "fortinet.com",
    label: "Fortinet",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  foursquare_com: {
    url: "foursquare.com",
    label: "Foursquare",
    deprecated: true,
    covered: {},
  },
  fourthwall_tv: {
    url: "fourthwall.tv",
    label: "FourthWall Media",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  fraiser_org: {
    url: "fraiser.org",
    label: "Fraiser",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  frankieone_com: {
    url: "frankieone.com",
    label: "FrankieOne",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "franklin-madison_com": {
    url: "franklin-madison.com",
    label: "Franklin Madison Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  freebackgroundchecks_com: {
    url: "freebackgroundchecks.com",
    label: "Freebackgroundchecks",
    deprecated: true,
    covered: {},
  },
  freedomsoft_com: {
    url: "freedomsoft.com",
    label: "FreedomSoft",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  free_people_directory: {
    url: "freepeopledirectory.com",
    label: "Free People Directory",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePostalAddress: true,
  },
  freephonetracer: {
    url: "freephonetracer.com",
    label: "Free Phone Tracer",
    deprecated: true,
    covered: {},
  },
  frescodata_com: {
    url: "frescodata.com",
    label: "Frescodata",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  frontier_com: {
    url: "frontier.com",
    label: "Frontier",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  fullcontact_com: {
    url: "fullcontact.com",
    label: "Full Contact",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  gaiagps_com: {
    url: "gaiagps.com",
    label: "Gaia GPS",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  geminiadvisory_com: {
    url: "geminiadvisory.com",
    label: "Gemini Advisory",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  geninfo_com: {
    url: "geninfo.com",
    label: "General Information Solution",
    deprecated: true,
    covered: {},
  },
  getprivynow_com: {
    url: "getprivynow.com",
    label: "Privy",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  getrev_ai: {
    url: "getrev.ai",
    label: "LeadCrunch dba Rev",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  gfk_com: {
    url: "gfk.com",
    label: "GFK",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ghrr_com: {
    url: "ghrr.com",
    label: "GHRR",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  giantpartners_com: {
    url: "giantpartners.com",
    label: "Giant Partners",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  gladiknow: {
    url: "gladiknow.com",
    label: "Glad I Know",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  globaldata_com: {
    url: "globaldata.com",
    label: "GlobalData",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  globalpaymentsinc_com: {
    url: "globalpaymentsinc.com",
    label: "Global Payments Check Services",
    deprecated: true,
    covered: {},
  },
  gohunt_com: {
    url: "gohunt.com",
    label: "GOHUNT",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  golookup: {
    url: "golookup.com",
    label: "Golookup",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  goodegg_io: {
    url: "goodegg.io",
    label: "Good Egg",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  goodhire_com: {
    url: "goodhire.com",
    label: "GoodHire",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  gostrata_com: {
    url: "gostrata.com",
    label: "Strata Company",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  governmentregistry: {
    url: "governmentregistry.org",
    label: "Governmentregistry",
    deprecated: true,
    covered: {},
  },
  grassrootsanalytics_com: {
    url: "grassrootsanalytics.com",
    label: "Grassroots Analytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  grata_com: {
    url: "grata.com",
    label: "Grata",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  gravyanalytics_com: {
    url: "gravyanalytics.com",
    label: "Gravy Analytics",
    deprecated: true,
    covered: {},
  },
  grayhairsoftware_com: {
    url: "grayhairsoftware.com",
    label: "GrayHair Software",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  greatlakeslists_com: {
    url: "greatlakeslists.com",
    label: "Great Lakes List Management",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  greenhouse_com: {
    url: "greenhouse.com",
    label: "Greenhouse",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  grepsr_com: {
    url: "grepsr.com",
    label: "Grepsr",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  groundtruth_com: {
    url: "groundtruth.com",
    label: "GroundTruth",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  growbots_com: {
    url: "growbots.com",
    label: "Growbots",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  gumgum_com: {
    url: "gumgum.com",
    label: "GumGum",
    deprecated: true,
    covered: {},
  },
  gundersondirect_com: {
    url: "gundersondirect.com",
    label: "Gunderson Direct",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  gwi_com: {
    url: "gwi.com",
    label: "GWI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  h2o_ai: {
    url: "h2o.ai",
    label: "H2O AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  hacknotice_com: {
    url: "hacknotice.com",
    label: "HackNotice",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  harmonresearch_com: {
    url: "harmonresearch.com",
    label: "Harmon Research Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  healthcare_com: {
    url: "healthcare.com",
    label: "Healthcare Inc",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  healthwisedata_com: {
    url: "healthwisedata.com",
    label: "HealthWise Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  hearst_com: {
    url: "hearst.com",
    label: "Hearst",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  heartbeat_ai: {
    url: "heartbeat.ai",
    label: "Heartbeat.AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  hellofyllo_com: {
    url: "hellofyllo.com",
    label: "Fyllo",
    deprecated: true,
    covered: {},
  },
  here_com: {
    url: "here.com",
    label: "Here",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  herosearches: {
    url: "herosearches.com",
    label: "Hero Searches",
    deprecated: true,
    covered: {},
  },
  yellowbook: {
    url: "hibu.com",
    label: "Hibu",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
  },
  hiplead_com: {
    url: "hiplead.com",
    label: "Hiplead",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  hippocraticai_com: {
    url: "hippocraticai.com",
    label: "Hippocratic AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  hireez_com: {
    url: "hireez.com",
    label: "hireEZ",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  hireright_com: {
    url: "hireright.com",
    label: "HireRight",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  hivestack_com: {
    url: "hivestack.com",
    label: "Hivestack",
    deprecated: true,
    covered: {},
  },
  hiya_com: {
    url: "hiya.com",
    label: "Hiya",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  homedata_com: {
    url: "homedata.com",
    label: "HomeData",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  home_metry: {
    url: "homemetry.com",
    label: "HomeMetry",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  homeownersmarketingservices_com: {
    url: "homeownersmarketingservices.com",
    label: "Homeowners Marketing Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  homesnap_com: {
    url: "homesnap.com",
    label: "Homesnap",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  huggingface_co: {
    url: "huggingface.co",
    label: "Huggingface",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  hunter_io: {
    url: "hunter.io",
    label: "Hunter Web Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  huntinfool_com: {
    url: "huntinfool.com",
    label: "Huntin' Fool",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  hybridtheory_com: {
    url: "hybridtheory.com",
    label: "Hybrid Theory",
    deprecated: true,
    covered: {},
  },
  "i-360_com": {
    url: "i-360.com",
    label: "i360",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ibm_com: {
    url: "ibm.com",
    label: "IBM",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  icims_com: {
    url: "icims.com",
    label: "iCIMS",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  id_land: {
    url: "id.land",
    label: "Land id",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  id5_io: {
    url: "id5.io",
    label: "ID5 Technology",
    deprecated: true,
    covered: {},
  },
  idagent_com: {
    url: "idagent.com",
    label: "ID Agent",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  idcrawl_com: {
    url: "idcrawl.com",
    label: "ID Crawl",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ididata_com: {
    url: "ididata.com",
    label: "Interactive Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  idm_us_com: {
    url: "idm.us.com",
    label: "IDM",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  idology_com: {
    url: "IDology.com",
    label: "IDology",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  idtrue: {
    url: "idtrue.com",
    label: "Idtrue",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  idxbroker_com: {
    url: "idxbroker.com",
    label: "IDX",
    deprecated: true,
    covered: {},
  },
  iix_com: {
    url: "iix.com",
    label: "Insurance Information Exchange",
    deprecated: true,
    covered: {},
  },
  immuniweb_com: {
    url: "immuniweb.com",
    label: "ImmuniWeb",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  imprintanalytics_io: {
    url: "imprintanalytics.io",
    label: "Imprint Analytics LLC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  inchecksolutions_com: {
    url: "inchecksolutions.com",
    label: "InCheck",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  inflection_ai: {
    url: "inflection.ai",
    label: "Inflection.AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  inflection_com: {
    url: "inflection.com",
    label: "Inflection.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  infocore_com: {
    url: "infocore.com",
    label: "Infocore",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  infocubic_com: {
    url: "infocubic.com",
    label: "Info Cubic",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  infodataplace_com: {
    url: "infodataplace.com",
    label: "Infodataplace",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  infofree_com: {
    url: "infofree.com",
    label: "Infofree.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "infomart-usa_com": {
    url: "infomart-usa.com",
    label: "InfoMart",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  infopay_com: {
    url: "infopay.com",
    label: "InfoPay",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  informa_com: {
    url: "informa.com",
    label: "Informa",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  information_com: {
    url: "information.com",
    label: "Information.com LLC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  inforver: {
    url: "inforver.com",
    label: "Inforver",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  infotracer: {
    url: "infotracer.com",
    label: "infotracer",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  infutor_com: {
    url: "infutor.com",
    label: "Infutor Data Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  inmarket_com: {
    url: "inmarket.com",
    label: "InMarket Media",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  inmates_searcher: {
    url: "inmatessearcher.com",
    label: "Inmatessearcher",
    deprecated: true,
    covered: {},
  },
  innovairre_com: {
    url: "innovairre.com",
    label: "Innovairre Communications",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  innovis_com: {
    url: "innovis.com",
    label: "Innovis",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  insecureweb_com: {
    url: "insecureweb.com",
    label: "InsecureWeb",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  insiderealestate_com: {
    url: "insiderealestate.com",
    label: "InsideRE",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  insideview_com: {
    url: "insideview.com",
    label: "InsideView",
    deprecated: true,
    covered: {},
  },
  instabase_com: {
    url: "instabase.com",
    label: "Instabase",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  instant_checkmate: {
    url: "instantcheckmate.com",
    label: "Instant Checkmate",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  intalytics_com: {
    url: "intalytics.com",
    label: "Intalytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  intel471_com: {
    url: "intel471.com",
    label: "Intel471",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  intelius: {
    url: "intelius.com",
    label: "Intelius",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  intellicorp_net: {
    url: "intellicorp.net",
    label: "IntelliCorp",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  intelx_io: {
    url: "intelx.io",
    label: "IntelligenceX",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  intentiq_com: {
    url: "intentiq.com",
    label: "Intent IQ",
    deprecated: true,
    covered: {},
  },
  intentmacro_com: {
    url: "intentmacro.com",
    label: "IntentMacro",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
  },
  internetbrands_com: {
    url: "internetbrands.com",
    label: "Internet Brands",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  interseller_io: {
    url: "interseller.io",
    label: "Interseller",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  intranetquorum_com: {
    url: "intranetquorum.com",
    label: "Leidos Digital Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
  },
  intsights_com: {
    url: "intsights.com",
    label: "IntSights",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ip2location_com: {
    url: "ip2location.com",
    label: "IP2Location.com",
    deprecated: true,
    covered: {},
  },
  ipreo_com: {
    url: "ipreo.com",
    label: "Ipreo",
    deprecated: true,
    covered: {},
  },
  ipsos_com: {
    url: "ipsos.com",
    label: "Ipsos",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  iqvia_com: {
    url: "iqvia.com",
    label: "IQVIA",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ispot_tv: {
    url: "ispot.tv",
    label: "iSpot.tv",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  issgovernance_com: {
    url: "issgovernance.com",
    label: "Institutional Shareholder Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  iwave_com: {
    url: "iwave.com",
    label: "iWave Information Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  iwco_com: {
    url: "iwco.com",
    label: "IWCO Direct",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ixactcontact_com: {
    url: "ixactcontact.com",
    label: "IXACT Contact Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  japsolson_com: {
    url: "japsolson.com",
    label: "Japs-Olson",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  jdmlistservices_com: {
    url: "jdmlistservices.com",
    label: "JDM List Services, LLC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  jdpower_com: {
    url: "jdpower.com",
    label: "J.D. Power",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  johnsnowlabs_com: {
    url: "johnsnowlabs.com",
    label: "John Snow Labs",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  juicebox_work: {
    url: "juicebox.work",
    label: "Juicebox",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  jumio_com: {
    url: "jumio.com",
    label: "Jumio",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  jungroup_com: {
    url: "jungroup.com",
    label: "Jun Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  justifacts_com: {
    url: "justifacts.com",
    label: "Justifacts",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  esiteanalytics_com: {
    url: "kalibrate.com",
    label: "Kalibrate",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  kbsynergy_com: {
    url: "kbsynergy.com",
    label: "KB Synergy",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  keyopinionleaders_com: {
    url: "keyopinionleaders.com",
    label: "KOLs",
    deprecated: true,
    covered: {},
  },
  keywordconnects_com: {
    url: "keywordconnects.com",
    label: "Keyword Connects",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  kidslivesafe: {
    url: "kidslivesafe.com",
    label: "Kids Live Safe",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  knowbe4_com: {
    url: "knowbe4.com",
    label: "KnowBe4",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  knowwho_com: {
    url: "knowwho.com",
    label: "KnowWho",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  komodohealth_com: {
    url: "komodohealth.com",
    label: "Komodo Health",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  kount_com: {
    url: "kount.com",
    label: "Kount",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  kpmg_com: {
    url: "kpmg.com",
    label: "KPMG",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  kubra_com: {
    url: "kubra.com",
    label: "KUBRA",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  kwold: {
    url: "kwold.com",
    label: "Kwold",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePostalAddress: true,
  },
  "l2-data_com": {
    url: "l2-data.com",
    label: "L2",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  labelbox_com: {
    url: "labelbox.com",
    label: "LabelBox",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lanvera_com: {
    url: "lanvera.com",
    label: "Lanvera",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  launchcontrol_us: {
    url: "launchcontrol.us",
    label: "Launch Control",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lead411_com: {
    url: "lead411.com",
    label: "Lead411",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadfuze_com: {
    url: "leadfuze.com",
    label: "LeadFuze",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadgenius_com: {
    url: "leadgenius.com",
    label: "LeadGenius",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadiq_com: {
    url: "leadiq.com",
    label: "LeadIQ",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadloft_com: {
    url: "leadloft.com",
    label: "LeadLoft",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadmemedia_com: {
    url: "leadmemedia.com",
    label: "Lead Me Media",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadpost_com: {
    url: "leadpost.com",
    label: "LeadPost",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadsherpa_com: {
    url: "leadsherpa.com",
    label: "Lead Sherpa",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadsmarket_com: {
    url: "leadsmarket.com",
    label: "LeadsMarket.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadspace_com: {
    url: "leadspace.com",
    label: "Leadspace",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadsplease_com: {
    url: "leadsplease.com",
    label: "Leads Please",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  leadverifier_com: {
    url: "leadverifier.com",
    label: "Anchor Computer",
    deprecated: true,
    covered: {},
  },
  leadzod_com: {
    url: "leadzod.com",
    label: "Leadzod.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  leena_ai: {
    url: "leena.ai",
    label: "Leena AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lexisnexis_com: {
    url: "lexisnexis.com",
    label: "LexisNexis",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  liferaftinc_com: {
    url: "liferaftinc.com",
    label: "LifeRaft",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lifesight_io: {
    url: "lifesight.io",
    label: "Lifesight",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  liftbasedata_com: {
    url: "liftbasedata.com",
    label: "LiftEngine",
    deprecated: true,
    covered: {},
  },
  liftengine_com: {
    url: "liftengine.com",
    label: "LiftEngine",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lightboxre_com: {
    url: "lightboxre.com",
    label: "Lightbox",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lightcast_io: {
    url: "lightcast.io",
    label: "Lightcast",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lighthouselist_com: {
    url: "lighthouselist.com",
    label: "Lighthouse List",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  likely_ai: {
    url: "likely.ai",
    label: "Likely Analytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lionsharemarketing_com: {
    url: "lionsharemarketing.com",
    label: "LionShare Marketing",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  listability_com: {
    url: "listability.com",
    label: "Listability",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  listgiant_com: {
    url: "listgiant.com",
    label: "List Giant",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  listmatch_com: {
    url: "listmatch.co",
    label: "ListMatch",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  listservicedirect_com: {
    url: "listservicedirect.com",
    label: "List Service Direct Inc.",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  listsonline_com: {
    url: "listsonline.com",
    label: "listsOnline",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  listsource_com: {
    url: "listsource.com",
    label: "CoreLogic",
    deprecated: true,
    covered: {},
  },
  liveintent_com: {
    url: "liveintent.com",
    label: "LiveIntent",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  liveramp_com: {
    url: "liveramp.com",
    label: "LiveRamp",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  locatefamily_com: {
    url: "locatefamily.com",
    label: "Locate Family",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  locatepeople: {
    url: "locatepeople.org",
    label: "Locate People",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  locateplus_com: {
    url: "locateplus.com",
    label: "LocatePLUS",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  locatesmarter_com: {
    url: "locatesmarter.com",
    label: "LocateSmarter",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  chime_me: {
    url: "lofty.com",
    label: "Lofty",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  logiq_com: {
    url: "logiq.com",
    label: "Logiq",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  loopme_com: {
    url: "loopme.com",
    label: "LoopMe",
    deprecated: true,
    covered: {},
  },
  lortondata_com: {
    url: "lortondata.com",
    label: "Lorton Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lotame_com: {
    url: "lotame.com",
    label: "Lotame Solutions",
    deprecated: true,
    covered: {},
  },
  loxo_co: {
    url: "loxo.co",
    label: "Loxo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  ltvco_com: {
    url: "ltvco.com",
    label: "The Lifetime Value Co.",
    deprecated: true,
    covered: {},
  },
  luc_id: {
    url: "luc.id",
    label: "Lucid Holdings",
    deprecated: true,
    covered: {},
  },
  lumen_com: {
    url: "lumen.com",
    label: "Lumen",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  lusha_co: {
    url: "lusha.co",
    label: "Lusha Systems",
    deprecated: true,
    covered: {},
  },
  lusha_com: {
    url: "lusha.com",
    label: "Lusha Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "m1-data_com": {
    url: "m1-data.com",
    label: "M1 Data & Analytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  madisonlogic_com: {
    url: "madisonlogic.com",
    label: "Madison Logic",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mailbanger_com: {
    url: "mailbanger.com",
    label: "Mail Banger",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mailpros_com: {
    url: "mailpros.com",
    label: "Professional Mail Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  marinusanalytics_com: {
    url: "marinusanalytics.com",
    label: "Marinus Analytics",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  marketforcecorp_com: {
    url: "marketforcecorp.com",
    label: "Market Force Corporation",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  marketleader_com: {
    url: "marketleader.com",
    label: "Market Leader",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  maxmind_com: {
    url: "maxmind.com",
    label: "MaxMind",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mcafee_com: {
    url: "mcafee.com",
    label: "McAfee",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mchdata_com: {
    url: "mchdata.com",
    label: "MCH Strategic Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mediamath_com: {
    url: "mediamath.com",
    label: "MediaMath",
    deprecated: true,
    covered: {},
  },
  mediaocean_com: {
    url: "mediaocean.com",
    label: "Mediaocean",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mediasourcesolutions_com: {
    url: "mediasourcesolutions.com",
    label: "Media Source Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mediawallah_com: {
    url: "mediawallah.com",
    label: "MediaWallah",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  melissa_com: {
    url: "melissa.com",
    label: "Melissa Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  meltwater_com: {
    url: "meltwater.com",
    label: "Meltwater",
    deprecated: true,
    covered: {},
  },
  merkleinc_com: {
    url: "merkleinc.com",
    label: "Merkle",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mib_com: {
    url: "mib.com",
    label: "MIB",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  microbilt_com: {
    url: "microbilt.com",
    label: "MicroBilt",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  milestonemarketingsolutions_com: {
    url: "milestonemarketingsolutions.com",
    label: "Milestone Marketing Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mmidirect_com: {
    url: "mmidirect.com",
    label: "MMI Direct",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mobilewalla_com: {
    url: "mobilewalla.com",
    label: "Mobilewalla",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  modernpostcard_com: {
    url: "modernpostcard.com",
    label: "Modern Postcard",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mogean_com: {
    url: "mogean.com",
    label: "Mogean",
    deprecated: true,
    covered: {},
  },
  mojosells_com: {
    url: "mojosells.com",
    label: "Mojo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  monevo_com: {
    url: "monevo.com",
    label: "Monevo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  monitorbase_com: {
    url: "monitorbase.com",
    label: "MonitorBase",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  monks_com: {
    url: "monks.com",
    label: "Media Monks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  monocl_com: {
    url: "monocl.com",
    label: "Monocl",
    deprecated: true,
    covered: {},
  },
  moodysanalytics_com: {
    url: "moodysanalytics.com",
    label: "Moody's",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  morrismarketinggroup_com: {
    url: "morrismarketinggroup.com",
    label: "Morris Marketing Group",
    deprecated: true,
    covered: {},
  },
  mosaicml_com: {
    url: "mosaicml.com",
    label: "MosaicML",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mrginc_com: {
    url: "mrginc.com",
    label: "Media Resource Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mrss_com: {
    url: "mrss.com",
    label: "M&R Strategic Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  msci_com: {
    url: "msci.com",
    label: "MSCI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  msnj_us: {
    url: "msnj.us",
    label: "MicroSystems-NJ.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mugshotlook: {
    url: "mugshotlook.com",
    label: "Mug Shot Look",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  multimedialists_com: {
    url: "multimedialists.com",
    label: "MULTIMEDIA LISTS, INC.",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  myflashcloud_com: {
    url: "myflashcloud.com",
    label: "FlashIntel",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  myheritage_com: {
    url: "myheritage.com",
    label: "My Heritage",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mylife: {
    url: "mylife.com",
    label: "Mylife",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  mypostageratesaver_com: {
    url: "mypostageratesaver.com",
    label: "Mypostageratesaver",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  myreipro_com: {
    url: "myreipro.com",
    label: "REIPro",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  mysmartmove_com: {
    url: "mysmartmove.com",
    label: "TransUnion SmartMove",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "n-focus_com": {
    url: "n-focus.com",
    label: "NFocus Consulting",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  namesandfacts: {
    url: "namesandfacts.com",
    label: "Names and Facts",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nationalcellulardirectory: {
    url: "nationalcellulardirectory.com",
    label: "National Cellular Directory",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nationalopinioninstitute_com: {
    url: "nationalopinioninstitute.com",
    label: "National Opinion Institute",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  nativo_com: {
    url: "nativo.com",
    label: "Nativo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nccreports_com: {
    url: "nccreports.com",
    label: "NCC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ncpsolutions_com: {
    url: "ncpsolutions.com",
    label: "NCP Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ncsolutions_com: {
    url: "ncsolutions.com",
    label: "NCSolutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nctue_com: {
    url: "nctue.com",
    label: "National Consumer Telecom & Utilities Exchange",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  near_com: {
    url: "near.com",
    label: "Near Intelligence Holdings",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  neighbor_report: {
    url: "neighbor.report",
    label: "Neighbor Report",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  neighbor_who: {
    url: "neighborwho.com",
    label: "NeighborWho",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  netronline_com: {
    url: "netronline.com",
    label: "NETR Online",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  netwisedata_com: {
    url: "netwisedata.com",
    label: "NetWise",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  neustar_biz: {
    url: "neustar.biz",
    label: "Neustar",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  newenglandfacts: {
    url: "newenglandfacts.com",
    label: "New England Facts",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  newsedge_com: {
    url: "newsedge.com",
    label: "Acquire Media U.S.",
    deprecated: true,
    covered: {},
  },
  "nextdayflyers.com": {
    url: "nextdayflyers.com",
    label: "Next Day Flyers",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nextdoor_com: {
    url: "nextdoor.com",
    label: "Nextdoor",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nextgen_com: {
    url: "nextgen.com",
    label: "NextGen Healthcare",
    deprecated: true,
    covered: {},
  },
  nextroll_com: {
    url: "nextroll.com",
    label: "NextRoll",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nextwavemarketingstrategies_com: {
    url: "nextwavemarketingstrategies.com",
    label: "Next Wave Marketing Strategies",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nexxagroup_com: {
    url: "nexxagroup.com",
    label: "Nexxa Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nielsen_com: {
    url: "nielsen.com",
    label: "Nielsen",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nj_parcels: {
    url: "njparcels.com",
    label: "NJ Parcels",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    includePostalAddress: true,
  },
  nj_property_records: {
    url: "njpropertyrecords.com",
    label: "NJ Property Records",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    includePostalAddress: true,
  },
  njtaxrecords_com: {
    url: "njtaxrecords.com",
    label: "NJ Tax Records",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  njtaxrecords_net: {
    url: "njtaxrecords.net",
    label: "NJ Tax Records",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  nomorobo_com: {
    url: "nomorobo.com",
    label: "Telephone Science",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  novalist_com: {
    url: "novalist.com",
    label: "NOVA List Company",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nsightful_com: {
    url: "nsightful.com",
    label: "nSightful",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  number_guru: {
    url: "numberguru.com",
    label: "NumberGuru",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  numlooker_com: {
    url: "numlooker.com",
    label: "NumLooker",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  numlookup_com: {
    url: "numlookup.com",
    label: "NumLookup",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nuwber: {
    url: "nuwber.com",
    label: "Nuwber",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  nymblr_com: {
    url: "nymblr.com",
    label: "NYMBLR",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  official_usa: {
    url: "officialusa.com",
    label: "OfficialUSA",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  offrs_com: {
    url: "offrs.com",
    label: "Offrs",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  ohio_resident_database: {
    url: "ohioresidentdatabase.com",
    label: "Ohio Resident Database",
    deprecated: true,
    covered: {},
  },
  okcaller_com: {
    url: "okcaller.com",
    label: "OkCaller.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  onaudience_com: {
    url: "onaudience.com",
    label: "OnAudience",
    deprecated: true,
    covered: {},
  },
  oncoreleads_com: {
    url: "oncoreleads.com",
    label: "Oncore Leads",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  onemata_com: {
    url: "onemata.com",
    label: "Onemata Corporation",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  onemodel_co: {
    url: "onemodel.co",
    label: "One Model",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  onfido_com: {
    url: "onfido.com",
    label: "Onfido",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  onlinesearches: {
    url: "onlinesearches.com",
    label: "Online Searches",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  onxmaps_com: {
    url: "onxmaps.com",
    label: "onXmaps",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  openai_com: {
    url: "openai.com",
    label: "Open AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  opencorporates_com: {
    url: "opencorporates.com",
    label: "OpenCorporates",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  opengovus_com: {
    url: "opengovus.com",
    label: "OpenGovUS",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  openonline_com: {
    url: "openonline.com",
    label: "OPENonline",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  openpeoplesearch_com: {
    url: "openpeoplesearch.com",
    label: "The Open Data People",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  openx_com: {
    url: "openx.com",
    label: "OpenX Technologies",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  oracle_com: {
    url: "oracle.com",
    label: "Oracle",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  orangetreescreening_com: {
    url: "orangetreescreening.com",
    label: "Orange Tree",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  outboundengine_com: {
    url: "outboundengine.com",
    label: "OutboundEngine",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  outlogic_io: {
    url: "outlogic.io",
    label: "Outlogic",
    deprecated: true,
    covered: {},
  },
  outpost24_com: {
    url: "outpost24.com",
    label: "Outpost24",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  outwardmedia_com: {
    url: "outwardmedia.com",
    label: "Outward Media",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  owler_com: {
    url: "owler.com",
    label: "Owler",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  ownerly: {
    url: "ownerly.com",
    label: "Ownerly",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  pacificeast_com: {
    url: "pacificeast.com",
    label: "PacificEast",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  palantir_com: {
    url: "palantir.com",
    label: "Palantir",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  paloaltonetworks_com: {
    url: "paloaltonetworks.com",
    label: "Palo Alto Networks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  parade_pet: {
    url: "parade.pet",
    label: "Pet Parade",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  paradym_com: {
    url: "paradym.com",
    label: "Paradym Digital Marketing",
    deprecated: true,
    covered: {},
  },
  path2response_com: {
    url: "path2response.com",
    label: "Path2Response",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  pbinfo_com: {
    url: "pbinfo.com",
    label: "Pension Benefit Information",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  peachtreedata_com: {
    url: "peachtreedata.com",
    label: "Peachtree Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  peek_you: {
    url: "peekyou.com",
    label: "PeekYou",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  people_background_check: {
    url: "people-background-check.com",
    label: "Peoplebackgroundcheck",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  people_search: {
    url: "people-search.org",
    label: "People-Search",
    deprecated: true,
    covered: {},
  },
  people_ai: {
    url: "people.ai",
    label: "People.ai",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  peopleconnect_us: {
    url: "peopleconnect.us",
    label: "PeopleConnect",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  peopledatalabs_com: {
    url: "peopledatalabs.com",
    label: "People Data Labs",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  people_finder: {
    url: "peoplefinder.com",
    label: "PeopleFinder",
    deprecated: true,
    covered: {},
  },
  people_finder_free: {
    url: "Peoplefinderfree.com",
    label: "People Finder Free",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  people_finders: {
    url: "peoplefinders.com",
    label: "People Finders",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  peoplefindersdaas_com: {
    url: "peoplefindersdaas.com",
    label: "PeoplefindersDaaS",
    deprecated: true,
    covered: {},
  },
  peoplelooker: {
    url: "peoplelooker.com",
    label: "People Looker",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  peoplemon_com: {
    url: "peoplemon.com",
    label: "InGrav",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  peoplesearch_com: {
    url: "peoplesearch.com",
    label: "People Search",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  peoplesearch123: {
    url: "peoplesearch123.com",
    label: "People Search 123",
    deprecated: true,
    covered: {},
  },
  people_searcher: {
    url: "peoplesearcher.com",
    label: "The People Searchers",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  peoplesearchnow: {
    url: "peoplesearchnow.com",
    label: "PeopleSearchNow.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  people_search_usa: {
    url: "peoplesearchusa.org",
    label: "People Search USA",
    deprecated: true,
    covered: {},
  },
  peoplesmart: {
    url: "peoplesmart.com",
    label: "PeopleSmart",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  peoplewhiz: {
    url: "peoplewhiz.com",
    label: "PeopleWhiz",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  peoplewhiz_net: {
    url: "peoplewhiz.net",
    label: "PeopleWhiz",
    deprecated: true,
    covered: {},
  },
  peoplewhizr: {
    url: "peoplewhizr.com",
    label: "PeopleWhizr",
    deprecated: true,
    covered: {},
  },
  peoplewhizr_net: {
    url: "peoplewhizr.net",
    label: "PeopleWhizr",
    deprecated: true,
    covered: {},
  },
  peoplewizard: {
    url: "peoplewizard.net",
    label: "Peoplewizard",
    deprecated: true,
    covered: {},
  },
  permutive_com: {
    url: "permutive.com",
    label: "Permutive",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  perplexity_ai: {
    url: "perplexity.ai",
    label: "Perplexity",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  person_searchers: {
    url: "personsearchers.com",
    label: "Person Searchers",
    deprecated: true,
    covered: {},
  },
  persopo: {
    url: "persopo.com",
    label: "Persopo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  pfl_com: {
    url: "pfl.com",
    label: "PFL Tech",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  phonebooks: {
    url: "phonebooks.com",
    label: "Phonebooks",
    deprecated: true,
    covered: {},
  },
  phonelookup_com: {
    url: "phonelookup.com",
    label: "Phone Lookup",
    deprecated: true,
    covered: {},
  },
  phonevalidator_com: {
    url: "phonevalidator.com",
    label: "Phone Validator",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  pinnacledatasystems_com: {
    url: "pinnacledatasystems.com",
    label: "Pinnacle Data Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  pipl_com: {
    url: "pipl.com",
    label: "Pipl",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  pitchbook_com: {
    url: "pitchbook.com",
    label: "PitchBook Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  placeiq_com: {
    url: "placeiq.com",
    label: "Precisely Software/PlaceIQ",
    deprecated: true,
    covered: {},
  },
  plexuss_com: {
    url: "plexuss.com",
    label: "Plexuss",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  pogodata_org: {
    url: "pogodata.org",
    label: "Pogodata",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  popacta_com: {
    url: "popacta.com",
    label: "Pop Acta Media",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  porchgroupmedia_com: {
    url: "porchgroupmedia.com",
    label: "Porch Media Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  possiblenow_com: {
    url: "possiblenow.com",
    label: "PossibleNow Data Services",
    deprecated: true,
    covered: {},
  },
  postalytics_com: {
    url: "postalytics.com",
    label: "Postalytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  postcardmania_com: {
    url: "postcardmania.com",
    label: "PostcardMania",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  postgrid_com: {
    url: "postgrid.com",
    label: "Postgrid",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  postie_com: {
    url: "postie.com",
    label: "Postie",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "pre-employ_com": {
    url: "pre-employ.com",
    label: "Pre-employ.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  precisely_com: {
    url: "precisely.com",
    label: "Precisely",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  preferredcommunications_com: {
    url: "preferredcommunications.com",
    label: "Preferred Communications",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  printingforless_com: {
    url: "printingforless.com",
    label: "PrintingForLess",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  private_eye: {
    url: "privateeye.com",
    label: "PrivateEye",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  private_number_checker: {
    url: "PrivateNumberChecker.com",
    label: "Private Number Checker",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  privaterecords: {
    url: "privaterecords.net",
    label: "Private Records LLC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePostalAddress: true,
  },
  privatereports_com: {
    url: "privatereports.com",
    label: "Infomatics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  privco_com: {
    url: "privco.com",
    label: "PrivCo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  propelio_com: {
    url: "propelio.com",
    label: "Propelio",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  propeoplesearch_com: {
    url: "propeoplesearch.com",
    label: "Pro People Search",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  propertyradar_com: {
    url: "propertyradar.com",
    label: "PropertyRadar",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  propertyshark_com: {
    url: "propertyshark.com",
    label: "PropertyShark",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  propstream_com: {
    url: "propstream.com",
    label: "PropStream",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "prospect-cloud_com": {
    url: "prospect-cloud.com",
    label: "Prospect Cloud",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  prospectnow_com: {
    url: "prospectnow.com",
    label: "ProspectNow",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  prospectplus_com: {
    url: "prospectplus.com",
    label: "Prospect Plus",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  pub360: {
    url: "pub360.com",
    label: "Pub360",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  publicdatacheck: {
    url: "publicdatacheck.com",
    label: "National Data Analytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  public_data_digger: {
    url: "publicdatadigger.com",
    label: "Public Data Digger",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
  },
  public_data_usa: {
    url: "PublicDataUSA.com",
    label: "Public Data USA",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  publicinfoservices: {
    url: "publicinfoservices.com",
    label: "Public Information Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  publicrecordscenter_org: {
    url: "publicrecordscenter.org",
    label: "Public Record Center",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  public_records_now: {
    url: "publicrecordsnow.com",
    label: "Public Records Now",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
  },
  publicrecordsofficial_com: {
    url: "publicrecordsofficial.com",
    label: "Public Records Official",
    deprecated: true,
    covered: {},
  },
  publicrecordsreviews_com: {
    url: "publicrecordsreviews.com",
    label: "Public Records Review",
    deprecated: true,
    covered: {},
  },
  public_reports: {
    url: "publicreports.com",
    label: "Public Reports",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  publicsearcher: {
    url: "publicsearcher.com",
    label: "Public Searcher",
    deprecated: true,
    covered: {},
  },
  publicsrecords_com: {
    url: "publicsrecords.com",
    label: "Public Records",
    deprecated: true,
    covered: {},
  },
  pubmatic_com: {
    url: "pubmatic.com",
    label: "PubMatic",
    deprecated: true,
    covered: {},
  },
  pulsepoint_com: {
    url: "pulsepoint.com",
    label: "PulsePoint",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  pwc_com: {
    url: "pwc.com",
    label: "PricewaterhouseCoopers",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  quad_com: {
    url: "quad.com",
    label: "Quad/Graphics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  quaddatasolutions_com: {
    url: "quaddatasolutions.com",
    label: "QuadData Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  qualfon_com: {
    url: "qualfon.com",
    label: "Qualfon",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  qualifiedpresort_com: {
    url: "qualifiedpresort.com",
    label: "Qualified Presort Service",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  qualityskips_com: {
    url: "qualityskips.com",
    label: "Quality Skips",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  quantarium_com: {
    url: "quantarium.com",
    label: "Quantarium",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  quantifind_com: {
    url: "quantifind.com",
    label: "Quantifind",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  quick_people_trace: {
    url: "quickpeopletrace.com",
    label: "Quick People Trace",
    deprecated: true,
    covered: {},
  },
  quotient_com: {
    url: "quotient.com",
    label: "Quotient",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  radaris: {
    url: "radaris.com",
    label: "Radaris",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  radioreference_com: {
    url: "radioreference.com",
    label: "RadioRefeference",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  rampedup_io: {
    url: "rampedup.io",
    label: "RampedUp",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  rapid7_com: {
    url: "rapid7.com",
    label: "Rapid7",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reachmarketing_com: {
    url: "reachmarketing.com",
    label: "Reach Marketing",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  realeflow_com: {
    url: "realeflow.com",
    label: "Realeflow",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  realgeeks_com: {
    url: "realgeeks.com",
    label: "Real Geeks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  realidinc_com: {
    url: "realidinc.com",
    label: "Real ID",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  realpage_com: {
    url: "realpage.com",
    label: "Real Page",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  realsourcedata_com: {
    url: "realsourcedata.com",
    label: "RealSource",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  realtor_com: {
    url: "realtor.com",
    label: "Move, Inc.",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  recordedfuture_com: {
    url: "recordedfuture.com",
    label: "Recorded Future",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  records_finder: {
    url: "recordsfinder.com",
    label: "Records Finder",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  recruitcrm_com: {
    url: "recruitcrm.com",
    label: "RecruitCRM",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  redidata_com: {
    url: "redidata.com",
    label: "Redi-Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  redimail_com: {
    url: "redimail.com",
    label: "Redi-Mail Direct Marketing",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  redviolet_com: {
    url: "redviolet.com",
    label: "red violet",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  redx_com: {
    url: "redx.com",
    label: "REDX",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reflexblu_com: {
    url: "reflexblu.com",
    label: "Reflex Blu",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  regrid_com: {
    url: "regrid.com",
    label: "Regrid",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  rehold: {
    url: "rehold.com",
    label: "Rehold",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  reiblackbook_com: {
    url: "reiblackbook.com",
    label: "REI BlackBook",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reirail_com: {
    url: "reirail.com",
    label: "REI Rail",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reireply_com: {
    url: "reireply.com",
    label: "REI Reply",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reisift_io: {
    url: "reisift.io",
    label: "REI Sift",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reiskip_com: {
    url: "reiskip.com",
    label: "REISkip",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reliaquest_com: {
    url: "reliaquest.com",
    label: "ReliaQuest",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
  },
  relosoftware_com: {
    url: "relosoftware.com",
    label: "Relo Software",
    deprecated: true,
    covered: {},
  },
  relsci_com: {
    url: "relsci.com",
    label: "Relsci",
    deprecated: true,
    covered: {},
  },
  remax_com: {
    url: "remax.com",
    label: "RE/MAX",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  remine_com: {
    url: "remine.com",
    label: "Remine",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  remodeling_com: {
    url: "remodeling.com",
    label: "Remodeling.com",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  rentcast_io: {
    url: "rentcast.io",
    label: "RentCast",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  rentgrow_com: {
    url: "rentgrow.com",
    label: "RentGrow",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reonomy_com: {
    url: "reonomy.com",
    label: "Reonomy",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reportallusa_com: {
    url: "reportallusa.com",
    label: "ReportAll USA",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  republicwireless_com: {
    url: "republicwireless.com",
    label: "Republic Wireless",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  resimpli_com: {
    url: "resimpli.com",
    label: "REsimpli",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  resonate_com: {
    url: "resonate.com",
    label: "Resonate",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  responsesoulutionsllc_com: {
    url: "responsesoulutionsllc.com",
    label: "Response Solutions Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  revaluate_com: {
    url: "revaluate.com",
    label: "Revaluate",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  revealmobile_com: {
    url: "revealmobile.com",
    label: "Reveal Mobile",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  revealname: {
    url: "revealname.com",
    label: "Revealname",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  reveliolabs_com: {
    url: "reveliolabs.com",
    label: "Revelio Labs",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  reversephonelookup: {
    url: "reversephonelookup.com",
    label: "Reverse Phone Lookup",
    deprecated: true,
    covered: {},
  },
  revspringinc_com: {
    url: "revspringinc.com",
    label: "RevSpring",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  rgrmarketing_com: {
    url: "rgrmarketing.com",
    label: "RGR Marketing",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  rhetorik_com: {
    url: "rhetorik.com",
    label: "Rhetorik",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  richmediallc_com: {
    url: "richmediallc.com",
    label: "Rich Media LLC",
    deprecated: true,
    covered: {},
  },
  "rival-hr_com": {
    url: "rival-hr.com",
    label: "Rival",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  rkdgroup_com: {
    url: "rkdgroup.com",
    label: "RKD Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  robokiller_com: {
    url: "robokiller.com",
    label: "Teltech Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  rocadvertising_com: {
    url: "rocadvertising.com",
    label: "Rocadvertising",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  rocketreach_co: {
    url: "rocketreach.co",
    label: "RocketReach",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  roq_ad: {
    url: "roq.ad",
    label: "Roq.ad",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  rrd_com: {
    url: "rrd.com",
    label: "R. R. Donnelley & Sons",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  rxhistories_com: {
    url: "rxhistories.com",
    label: "Milliman",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sabio_inc: {
    url: "sabio.inc",
    label: "Sabio",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  safegraph_com: {
    url: "safegraph.com",
    label: "SafeGraph",
    deprecated: true,
    covered: {},
  },
  saferentsolutions_com: {
    url: "saferentsolutions.com",
    label: "SafeRent Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sagestreamllc_com: {
    url: "sagestreamllc.com",
    label: "SageStream",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  salesforce_com: {
    url: "salesforce.com",
    label: "Salesforce",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  salesintel_io: {
    url: "salesintel.io",
    label: "SalesIntel Research",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  samba_tv: {
    url: "samba.tv",
    label: "Samba TV",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sap_com: {
    url: "sap.com",
    label: "SAP",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sarma_com: {
    url: "sarma.com",
    label: "Sarma",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sattanalytics_com: {
    url: "sattanalytics.com",
    label: "SATT Analytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  sawyerdatadirect_com: {
    url: "sawyerdatadirect.com",
    label: "Sawyer Lists",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  scalablecommerce_com: {
    url: "scalablecommerce.com",
    label: "Scalable Commerce",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  scanbuy_com: {
    url: "scanbuy.com",
    label: "Scanbuy",
    deprecated: true,
    covered: {},
  },
  schneidergis_com: {
    url: "schneidergis.com",
    label: "Schneider Geospatial",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  scileads_com: {
    url: "scileads.com",
    label: "SciLeads",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  scrapingrobot_com: {
    url: "scrapingrobot.com",
    label: "Scraping Robot",
    deprecated: true,
    covered: {},
  },
  screeningreports_com: {
    url: "screeningreports.com",
    label: "Screening Reports",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sealed_records: {
    url: "sealedrecords.net",
    label: "Sealed Records",
    deprecated: true,
    covered: {},
  },
  seamless_ai: {
    url: "seamless.ai",
    label: "Seamless.ai",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  searchbug: {
    url: "searchbug.com",
    label: "Searchbug",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  search_people_free: {
    url: "searchpeoplefree.com",
    label: "Search People Free",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  searchpublicrecords: {
    url: "searchpublicrecords.com",
    label: "Search Public Records",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  searchquarry: {
    url: "searchquarry.com",
    label: "SearchQuarry",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sebis_com: {
    url: "sebis.com",
    label: "Sebis Direct",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  secreportinganalytics_com: {
    url: "secreportinganalytics.com",
    label: "SEC Reporting Analytics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  secret_info: {
    url: "secretinfo.org",
    label: "Secretinfo",
    deprecated: true,
    covered: {},
  },
  securitytrails_com: {
    url: "securitytrails.com",
    label: "SecurityTrails",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  seekout_io: {
    url: "seekout.io",
    label: "SeekOut",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  "semantic-ai_com": {
    url: "semantic-ai.com",
    label: "Semantic AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  semasio_com: {
    url: "semasio.com",
    label: "Semasio",
    deprecated: true,
    covered: {},
  },
  semcasting_com: {
    url: "semcasting.com",
    label: "Semcasting",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sentilink_com: {
    url: "sentilink.com",
    label: "SentiLink",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sentinelone_com: {
    url: "sentinelone.com",
    label: "SentinelOne",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sequeldm_com: {
    url: "sequeldm.com",
    label: "SeQuel Response",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sg360_com: {
    url: "sg360.com",
    label: "SG360",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  shadowdragon_io: {
    url: "shadowdragon.io",
    label: "Shadow Dragon",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sharelocalmedia_com: {
    url: "sharelocalmedia.com",
    label: "Share Local Media",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sharethis_com: {
    url: "sharethis.com",
    label: "Sharethis",
    deprecated: true,
    covered: {},
  },
  sheerid_com: {
    url: "sheerid.com",
    label: "SheerID",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sheriffsdepartment_net: {
    url: "sheriffsdepartment.net",
    label: "Sheriffsdepartment",
    deprecated: true,
    covered: {},
  },
  signalhire_com: {
    url: "signalhire.com",
    label: "SignalHire",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  silobreaker_com: {
    url: "silobreaker.com",
    label: "Silobreaker",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  simiocloud_com: {
    url: "simiocloud.com",
    label: "Simio Cloud",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  simpliverified_com: {
    url: "simpliverified.com",
    label: "SimpliVerified",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  siteimpact_com: {
    url: "siteimpact.com",
    label: "Site Impact",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  skipfast_com: {
    url: "skipfast.com",
    label: "Skip Fast",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  skipforce_com: {
    url: "skipforce.com",
    label: "Skip Force",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  skipgenie_com: {
    url: "skipgenie.com",
    label: "Skip Genie",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  skipsmasher_com: {
    url: "skipsmasher.com",
    label: "Skipmasher, Inc.",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  skopenow_com: {
    url: "skopenow.com",
    label: "Skopenow",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  skyetel_com: {
    url: "skyetel.com",
    label: "Skyetel",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  slashdotmedia_com: {
    url: "slashdotmedia.com",
    label: "Slashdot Media",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  slcyber_io: {
    url: "slcyber.io",
    label: "Searchlight Cyber",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  smacomm_com: {
    url: "smacomm.com",
    label: "SMA Communications",
    deprecated: true,
    covered: {},
  },
  smartbackgroundchecks_com: {
    url: "smartbackgroundchecks.com",
    label: "Smartbackgroundchecks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  smarteinc_com: {
    url: "smarteinc.com",
    label: "SMARTe",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  smartmove_us: {
    url: "smartmove.us",
    label: "SmartMove",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  smartpress_com: {
    url: "smartpress.com",
    label: "Smartpress",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  smartsoftdq_com: {
    url: "smartsoftdq.com",
    label: "SmartSoft DQ",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  smarttracing_io: {
    url: "smarttracing.io",
    label: "Smart Tracing",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  smarty_com: {
    url: "smarty.com",
    label: "Smarty",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  smartzip_com: {
    url: "smartzip.com",
    label: "SmartZip",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  "sms-inc_com": {
    url: "sms-inc.com",
    label: "Specialists Marketing Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  snorkel_ai: {
    url: "snorkel.ai",
    label: "Snorkel AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  snov_io: {
    url: "snov.io",
    label: "Snovio",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  snusbase_com: {
    url: "snusbase.com",
    label: "Snusbase",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  social_catfish: {
    url: "socialcatfish.com",
    label: "Social Catfish",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  socialgist_com: {
    url: "socialgist.com",
    label: "Socialgist",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  socialintel_com: {
    url: "socialintel.com",
    label: "Social Intelligence",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sociallinks_io: {
    url: "sociallinks.io",
    label: "Social Links",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  socure_com: {
    url: "socure.com",
    label: "Socure",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  solpub_com: {
    url: "solpub.com",
    label: "Solution Publishing",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sontiq_com: {
    url: "sontiq.com",
    label: "Sontiq",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sourceitmarketing_com: {
    url: "sourceitmarketing.com",
    label: "SourceIT Technologies",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  sourcelink_com: {
    url: "sourcelink.com",
    label: "SourceLink",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sourcewhale_com: {
    url: "sourcewhale.com",
    label: "SourceWhale",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  spanglobalservices_com: {
    url: "spanglobalservices.com",
    label: "Span Global Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  speedeondata_com: {
    url: "speedeondata.com",
    label: "Speedeon Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  spglobal_com: {
    url: "spglobal.com",
    label: "S&P Global",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  spokeo: {
    url: "spokeo.com",
    label: "Spokeo",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  spycloud_com: {
    url: "spycloud.com",
    label: "SpyCloud",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  spy_dialer: {
    url: "spydialer.com",
    label: "Spy Dialer",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  spyfly: {
    url: "spyfly.com",
    label: "Spyfly",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  srv1st_com: {
    url: "srv1st.com",
    label: "Service 1st Information Solutions LLC",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  stability_ai: {
    url: "stability.ai",
    label: "Stability AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  stanford_edu: {
    url: "stanford.edu",
    label: "Stanford Institute for Human-Centered Artificial Intelligence",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  stannp_com: {
    url: "stannp.com",
    label: "Stannp",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  statara_com: {
    url: "statara.com",
    label: "Statara Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  stateinfoservices_com: {
    url: "stateinfoservices.com",
    label: "State Information Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  state_records: {
    url: "staterecords.org",
    label: "StateRecords",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  statlistics_com: {
    url: "statlistics.com",
    label: "Statlistics",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  statsocial_com: {
    url: "statsocial.com",
    label: "StatSocial",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sterlingcheck_com: {
    url: "sterlingcheck.com",
    label: "Sterling",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  sterlingdatacompany_com: {
    url: "sterlingdatacompany.com",
    label: "Sterling Data Company",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  stirista_com: {
    url: "stirista.com",
    label: "Stirista",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  strategicdataintelligence_com: {
    url: "strategicdataintelligence.com",
    label: "Strategic Data Intelligence",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  superpages_com: {
    url: "superpages.com",
    label: "SuperPages",
    deprecated: true,
    covered: {},
  },
  swordfish_ai: {
    url: "swordfish.ai",
    label: "Swordfish AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  sync_me: {
    url: "sync.me",
    label: "SyncMe",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  tapad_com: {
    url: "tapad.com",
    label: "Tapad",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  targetsmart_com: {
    url: "targetsmart.com",
    label: "TargetSmart Communications",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tazworks_com: {
    url: "tazworks.com",
    label: "TazWorks",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tdmg_com: {
    url: "tdmg.com",
    label: "ThinkDirect",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  teamable_com: {
    url: "teamable.com",
    label: "Teamable",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  techsalerator_com: {
    url: "techsalerator.com",
    label: "Techsalerator",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  techtarget_com: {
    url: "techtarget.com",
    label: "TechTarget",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tecmailing_com: {
    url: "tecmailing.com",
    label: "TEC Mailing Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  telecheck_com: {
    url: "telecheck.com",
    label: "TeleCheck Services",
    deprecated: true,
    covered: {},
  },
  telefi_app: {
    url: "telefi.app",
    label: "Telefi",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  telephonedirectories: {
    url: "telephonedirectories.us",
    label: "Telephonedirectories",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  telephonelists_biz: {
    url: "telephonelists.biz",
    label: "Telephonelists",
    deprecated: true,
    covered: {},
  },
  telephonescience_com: {
    url: "telephonescience.com",
    label: "Telephone Science Corp.",
    deprecated: true,
    covered: {},
  },
  telnyx_com: {
    url: "telnyx.com",
    label: "Telnyx",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  thats_them: {
    url: "thatsthem.com",
    label: "ThatsThem",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  theaddressers_com: {
    url: "theaddressers.com",
    label: "The Addressers",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  thebridgecorp_com: {
    url: "thebridgecorp.com",
    label: "The Bridge Corp",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  thedatacenter_com: {
    url: "thedatacenter.com",
    label: "Data Center",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  thedatatrust_com: {
    url: "thedatatrust.com",
    label: "Data Trust",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  themailshark_com: {
    url: "themailshark.com",
    label: "The Mail Shark",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  theretailequation_com: {
    url: "theretailequation.com",
    label: "The Retail Equation",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  therooftop_io: {
    url: "therooftop.io",
    label: "Rooftop Digital",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  theworknumber_com: {
    url: "theworknumber.com",
    label: "The Work Number",
    deprecated: true,
    covered: {},
  },
  thisnumber: {
    url: "thisnumber.com",
    label: "ThisNumber",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    includePhone: true,
  },
  thomsonreuters_com: {
    url: "thomsonreuters.com",
    label: "Thomson Reuters",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  threatq_com: {
    url: "threatq.com",
    label: "ThreatQuotient",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  throtle_io: {
    url: "throtle.io",
    label: "Throtle",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  thryv_com: {
    url: "thryv.com",
    label: "Thryv",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tkinteractive_com: {
    url: "tkinteractive.com",
    label: "TK Interactive",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tlo_com: {
    url: "tlo.com",
    label: "TLOxp",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tmobile_com: {
    url: "tmobile.com",
    label: "T-Mobile",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  tnsi_com: {
    url: "tnsi.com",
    label: "Transaction Network Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  together_ai: {
    url: "together.ai",
    label: "Together AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  top4backgroundchecks_com: {
    url: "top4backgroundchecks.com",
    label: "Top4Backgroundchecks",
    deprecated: true,
    covered: {},
  },
  tophap_com: {
    url: "tophap.com",
    label: "TopHap",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  topproducer_com: {
    url: "topproducer.com",
    label: "Top Producer",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  torchx_com: {
    url: "torchx.com",
    label: "TorchX",
    deprecated: true,
    covered: {},
  },
  transunion_com: {
    url: "transunion.com",
    label: "TransUnion",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tu_trads_com: {
    url: "transunion.com",
    label: "TransUnion Risk and Alternative Data Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tu_turss_com: {
    url: "transunion.com",
    label: "TransUnion Rental Screening Services",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  trapcall_com: {
    url: "trapcall.com",
    label: "Epic Enterprises",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  trestleiq_com: {
    url: "trestleiq.com",
    label: "Trestle Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  truecaller_com: {
    url: "truecaller.com",
    label: "Truecaller International",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  truecnam_com: {
    url: "truecnam.com",
    label: "TrueCNAM",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  truedata_co: {
    url: "truedata.co",
    label: "TrueData Solutions",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  truencoa_com: {
    url: "truencoa.com",
    label: "TrueNCOA",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  true_people_search: {
    url: "truepeoplesearch.com",
    label: "TruePeopleSearch.com",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  truescreen_com: {
    url: "truescreen.com",
    label: "Truescreen",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  truework_com: {
    url: "truework.com",
    label: "Truework",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  trustedemployees_com: {
    url: "trustedemployees.com",
    label: "Trusted Employees",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  trustwave_com: {
    url: "trustwave.com",
    label: "Trustwave",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  truth_finder: {
    url: "truthfinder.com",
    label: "TruthFinder",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  truthrecord_org: {
    url: "truthrecord.org",
    label: "Truthrecord",
    covered: {
      PREMIUM: true,
    },
    includePostalAddress: true,
  },
  truthset_io: {
    url: "truthset.io",
    label: "Truthset",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tryelevate_com: {
    url: "tryelevate.com",
    label: "Elevate",
    deprecated: true,
    covered: {},
  },
  tsico_com: {
    url: "tsico.com",
    label: "TSI",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tsys_com: {
    url: "tsys.com",
    label: "TSYS",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  tunnldata_com: {
    url: "tunnldata.com",
    label: "Tunnl",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  twilio_com: {
    url: "twilio.com",
    label: "Twilio",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  uconfirm_com: {
    url: "uconfirm.com",
    label: "uConfirm",
    deprecated: true,
    covered: {},
  },
  ufind: {
    url: "ufind.name",
    label: "Ufind",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  unearthcampaigns_com: {
    url: "unearthcampaigns.com",
    label: "Unearth Campaigns",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  unicourt_com: {
    url: "unicourt.com",
    label: "UniCourt",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  unitedone_com: {
    url: "unitedone.com",
    label: "United One Resources",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  unity_com: {
    url: "unity.com",
    label: "Unity",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  universalbackground_com: {
    url: "universalbackground.com",
    label: "Universal Background Screening",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  unmask: {
    url: "Unmask.com",
    label: "UnMask",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  uplead_com: {
    url: "uplead.com",
    label: "UpLead",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  upswellmarketing_com: {
    url: "upswellmarketing.com",
    label: "Upswell",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  urbanfootprint_com: {
    url: "urbanfootprint.com",
    label: "Urban Footprint",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  "usa-official_com": {
    url: "usa-official.com",
    label: "USA Public Data Search",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  usa_people_search: {
    url: "usa-people-search.com",
    label: "USAPeopleSearch",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  usadata_com: {
    url: "usadata.com",
    label: "USAData",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  usatrace: {
    url: "usatrace.com",
    label: "USATrace",
    deprecated: true,
    covered: {},
  },
  uscellular_com: {
    url: "uscellular.com",
    label: "US Cellular",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  usdatacorporation_com: {
    url: "usdatacorporation.com",
    label: "US Data Corporation",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  usinfosearch_com: {
    url: "usinfosearch.com",
    label: "US Info Search",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  uslandgrid_com: {
    url: "uslandgrid.com",
    label: "Land Grid",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePostalAddress: true,
  },
  usmarketinggrp_com: {
    url: "usmarketinggrp.com",
    label: "US Marketing Group",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  us_phone_book: {
    url: "USPhoneBook.com",
    label: "US Phone Book",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  usphonelookup_com: {
    url: "usphonelookup.com",
    label: "US Phone Lookup",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  usphonesearch_com: {
    url: "usphonesearch.com",
    label: "US Phone Search",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  us_search: {
    url: "ussearch.com",
    label: "US Search",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  vainu_com: {
    url: "vainu.com",
    label: "Vainu",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  validnumber: {
    url: "validnumber.com",
    label: "Valid Number",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  valpak_com: {
    url: "valpak.com",
    label: "Valpak",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  vdx_tv: {
    url: "vdx.tv",
    label: "VDX.tv",
    deprecated: true,
    covered: {},
  },
  veeva_com: {
    url: "veeva.com",
    label: "Veeva Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  venpath_net: {
    url: "venpath.net",
    label: "Venpath",
    deprecated: true,
    covered: {},
  },
  ventiveiq_com: {
    url: "ventiveiq.com",
    label: "VentiveIQ",
    deprecated: true,
    covered: {},
  },
  veraset_com: {
    url: "veraset.com",
    label: "Veraset",
    deprecated: true,
    covered: {},
  },
  verecor: {
    url: "Verecor.com",
    label: "Verecor",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  vericast_com: {
    url: "vericast.com",
    label: "Vericast",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  vericora: {
    url: "vericora.com",
    label: "Vericora",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  veridion_com: {
    url: "veridion.com",
    label: "Veridion",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  verifacts_com: {
    url: "verifacts.com",
    label: "VeriFacts",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  verifiedcredentials_com: {
    url: "verifiedcredentials.com",
    label: "Verified Credentials",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  veriforia: {
    url: "veriforia.com",
    label: "Veriforia",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  verinext_com: {
    url: "verinext.com",
    label: "Verinext",
    deprecated: true,
    covered: {},
  },
  veripages: {
    url: "veripages.com",
    label: "Veripages",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  verisk_com: {
    url: "verisk.com",
    label: "Verisk",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  verispy: {
    url: "verispy.com",
    label: "Verispy",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  veritedata_com: {
    url: "veritedata.com",
    label: "Verite Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  verizon_com: {
    url: "verizon.com",
    label: "Verizon",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  versium_com: {
    url: "versium.com",
    label: "Versium",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  verticalscreen_com: {
    url: "verticalscreen.com",
    label: "Vertical Screen",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  vertify_com: {
    url: "vertify.com",
    label: "Vertify",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  vian_ai: {
    url: "vian.ai",
    label: "Vian AI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  viantinc_com: {
    url: "viantinc.com",
    label: "Viant Technology",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  videoamp_com: {
    url: "videoamp.com",
    label: "VideoAmp",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  vippreferred_com: {
    url: "vippreferred.com",
    label: "VIP Preferred",
    deprecated: true,
    covered: {},
  },
  virtory: {
    url: "virtory.com",
    label: "Virtory",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  virtualproperties_com: {
    url: "virtualproperties.com",
    label: "Virtual Properties",
    deprecated: true,
    covered: {},
  },
  "vistaprint.com": {
    url: "vistaprint.com",
    label: "Vista Print",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  vistaprintcorporate_com: {
    url: "vistaprintcorporate.com",
    label: "Vista Corporate Solutions",
    deprecated: true,
    covered: {},
  },
  voicepad_com: {
    url: "voicepad.com",
    label: "Elm Street Technology",
    deprecated: true,
    covered: {},
  },
  vonage_com: {
    url: "vonage.com",
    label: "Vonage",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
  },
  voteref_com: {
    url: "voteref.com",
    label: "VoteRef",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  voter_records: {
    url: "VoterRecords.com",
    label: "Voter Records",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePostalAddress: true,
  },
  vrtcal_com: {
    url: "vrtcal.com",
    label: "VRTCAL Markets",
    deprecated: true,
    covered: {},
  },
  vulcan7_com: {
    url: "vulcan7.com",
    label: "Vulcan7",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  warmly_ai: {
    url: "warmly.ai",
    label: "Warmly",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
  },
  watchguard_com: {
    url: "watchguard.com",
    label: "WatchGuard Technologies",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  wealthengine_com: {
    url: "wealthengine.com",
    label: "WealthEngine",
    deprecated: true,
    covered: {},
  },
  wealthx_com: {
    url: "wealthx.com",
    label: "Wealthx",
    deprecated: true,
    covered: {},
  },
  wearemoore_com: {
    url: "wearemoore.com",
    label: "Moore",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  webbula_com: {
    url: "webbula.com",
    label: "Webbula",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  webz_io: {
    url: "webz.io",
    label: "Webz.io",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  weinform_org: {
    url: "weinform.org",
    label: "We Inform",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  wellnut: {
    url: "wellnut.com",
    label: "Wellnut",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  whitehat_com: {
    url: "whitehat.com",
    label: "Whitehat",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  white_pages: {
    url: "whitepages.com",
    label: "Whitepages",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  whoisxmlapi_com: {
    url: "whoisxmlapi.com",
    label: "Whois API",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  wiland_com: {
    url: "wiland.com",
    label: "Wiland",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  workable_com: {
    url: "workable.com",
    label: "Workable",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  writer_com: {
    url: "writer.com",
    label: "Writer",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  x_ai: {
    url: "x.ai",
    label: "xAI",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  xactus_com: {
    url: "xactus.com",
    label: "Xactus",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  xcelerated_com: {
    url: "xcelerated.com",
    label: "Xcelerated Data",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  xembly_com: {
    url: "xembly.com",
    label: "Xembly",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  xlek: {
    url: "xlek.com",
    label: "Xlek",
    deprecated: true,
    covered: {},
  },
  xome_com: {
    url: "xome.com",
    label: "Xome",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  yardi_com: {
    url: "yardi.com",
    label: "Yardi Systems",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  yellow_pages: {
    url: "Yellowpages.com",
    label: "Yellowpages",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  yellowpagesdirectory_com: {
    url: "yellowpagesdirectory.com",
    label: "Yellowpages Directory",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  youmail_com: {
    url: "youmail.com",
    label: "YouMail",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
  },
  z57_com: {
    url: "z57.com",
    label: "Z57",
    deprecated: true,
    covered: {},
  },
  zaba_search: {
    url: "zabasearch.com",
    label: "Zabasearch",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
      NONE: true,
    },
    scraper: true,
    includePhone: true,
    includePostalAddress: true,
  },
  zdperformancemarketing_com: {
    url: "zdperformancemarketing.com",
    label: "Salesify",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  zerofox_com: {
    url: "zerofox.com",
    label: "ZeroFox",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  zetaglobal_com: {
    url: "zetaglobal.com",
    label: "Zeta Global",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  zillow_com: {
    url: "zillow.com",
    label: "Zillow",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  zincwork_com: {
    url: "zincwork.com",
    label: "Zinc Work",
    covered: {
      PREMIUM: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  zlookup_com: {
    url: "zlookup.com",
    label: "Zlookup.com",
    deprecated: true,
    covered: {},
  },
  zoominfo_com: {
    url: "zoominfo.com",
    label: "ZoomInfo Technologies",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
      BASIC_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  zs_com: {
    url: "zs.com",
    label: "ZS Associates",
    covered: {
      PREMIUM: true,
      STANDARD_DL: true,
    },
    includePhone: true,
    includePostalAddress: true,
  },
  zurple_com: {
    url: "zurple.com",
    label: "Zurple",
    deprecated: true,
    covered: {},
  },
};

import { ArgumentError } from "../errors";
import { SubscriptionDefinitions, SubscriptionType } from "../subscription-types";

export interface BrokerProps {
  url: string;
  label: string;
  optout_url?: string;
  scraper?: boolean;
  includePhone?: boolean;
  includePostalAddress?: boolean;
  takedown_email?: string;
  manual_removals?: boolean;
  deprecated?: boolean;
  covered: {
    NONE?: boolean;
    BASIC_DL?: boolean;
    STANDARD_DL?: boolean;
    PREMIUM?: boolean;
  };
  placeholder?: boolean;
}

export interface BrokerMapProps {
  [key: string]: BrokerProps;
}

export const brokerIds = Object.keys(Brokers);

export const IsBrokerCoveredFunctions = {
  peopleSearchSitesOnly: (broker: BrokerProps) => Boolean(broker.covered.NONE),
  basicTierBrokers: (broker) => Boolean(broker.covered.BASIC_DL),
  standardTierBrokers: (broker) =>
    Boolean(broker.covered.NONE) ||
    Boolean(broker.covered.BASIC_DL) ||
    Boolean(broker.covered.STANDARD_DL),
  allPremiumBrokers: (broker) =>
    Boolean(broker.covered.NONE) ||
    Boolean(broker.covered.PREMIUM) ||
    Boolean(broker.covered.STANDARD_DL) ||
    Boolean(broker.covered.BASIC_DL),
  noBrokers: () => false,
};
const isBrokerCoveredFunctionKeys = Object.keys(IsBrokerCoveredFunctions);
export const IsBrokerCoveredFunctionTypeSchema = z.enum([
  isBrokerCoveredFunctionKeys[0],
  ...isBrokerCoveredFunctionKeys.slice(1),
]);
export type IsBrokerCoveredFunctionType = z.infer<typeof IsBrokerCoveredFunctionTypeSchema>;

export function getBrokerCoveredFuncForSubscription(subscriptionType: SubscriptionType) {
  if (!subscriptionType || !SubscriptionDefinitions[subscriptionType]?.isBrokerCoveredFunction) {
    throw new ArgumentError({ debugMessage: "Invalid subscriptionType" });
  }
  return IsBrokerCoveredFunctions[
    SubscriptionDefinitions[subscriptionType].isBrokerCoveredFunction
  ];
}

/**
 * Return brokers that are considered "included" for the given subscription
 */
export type BrokerPropsWithId = BrokerProps & { brokerId: string };
export const getCoveredBrokers = (subscriptionType: SubscriptionType): BrokerPropsWithId[] => {
  const isBrokerCoveredFunc = getBrokerCoveredFuncForSubscription(subscriptionType);
  if (!isBrokerCoveredFunc)
    throw new ArgumentError({
      debugMessage: `No isBrokerCoveredFunction defined for subscription type: ${subscriptionType}`,
    });
  return Object.entries(Brokers)
    .map(([brokerId, data]) => ({ ...data, brokerId }))
    .filter((broker) => isBrokerCoveredFunc(broker))
    .sort(({ label: a }, { label: b }) => a.localeCompare(b));
};

/**
 * Return brokers that we can easily scrape with publicly accessible
 * profiles, i.e. people search sites
 */
export const getPeopleSearchSiteBrokers = () => {
  return Object.entries(Brokers)
    .map(([brokerId, data]) => ({ ...data, brokerId }))
    .filter((broker) => Boolean(broker.scraper))
    .sort(({ label: a }, { label: b }) => a.localeCompare(b));
};

export const scrapedBrokerCount = getPeopleSearchSiteBrokers().length;
// brokers marked "enable in app" in data broker CRM sheet
export const allBrokers = Object.entries(Brokers)
  .map(([brokerId, data]) => ({ ...data, brokerId }))
  .filter((broker) => IsBrokerCoveredFunctions.allPremiumBrokers(broker))
  .sort(({ label: a }, { label: b }) => a.localeCompare(b));
export const brokersInAppCount = allBrokers.length;

export const placeholderBrokers = () => {
  return Object.entries(Brokers)
    .map(([brokerId, data]) => ({ ...data, brokerId }))
    .filter((broker) => Boolean(broker.placeholder));
};

// eslint-disable-next-line import/no-default-export
export default Brokers;
