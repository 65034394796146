import { cva, type VariantProps } from "class-variance-authority";
import Link from "next/link";
import { HTMLProps } from "react";

import { cn } from "./helpers";
import { Icon } from "./Icon";

// Standard colors. Should match index.css
const enum Color {
  Red = "#F04438",
  Blue = "#116df8",
  Orange = "#F79009",
  Green = "#17B26A",
  Gray = "#83888c",
}

export interface StatusProps {
  label: string;
  next: any[];
  title?: string;
  icon?: string;
  color?: string;
}

export interface StatusEnumProps {
  [key: string]: StatusProps;
}

export type StatusEnumType =
  | "all"
  | "possible_match"
  | "confirmed_match"
  | "removal_requested"
  | "removed"
  | "bad_match"
  | "unremovable";

export const STATUS_ENUM: StatusEnumProps = {
  all: {
    label: "All records",
    next: ["bad_match", "confirmed_match", "removal_requested", "removed", "unremovable"],
    title: "All record needs to be verified",
  },
  possible_match: {
    label: "To Verify",
    icon: "QuestionMark",
    next: ["bad_match", "confirmed_match"],
    title: "Record needs to be verified",
  },
  confirmed_match: {
    label: "Found",
    icon: "Exclamation",
    color: Color.Red,
    next: ["bad_match", "removal_requested"],
    title: "Record found on databroker page",
  },
  removal_requested: {
    label: "Removing",
    icon: "Clock",
    color: Color.Orange,
    next: ["confirmed_match", "removed", "unremovable"],
    title: "Record is in removing process",
  },
  removed: {
    label: "Removed",
    icon: "CheckCircle",
    color: Color.Green,
    next: ["confirmed_match"],
    title: "Record removed",
  },
  bad_match: {
    label: "Bad Match",
    icon: "Remove",
    next: ["confirmed_match"],
    title: "Record is bad match",
  },
  unremovable: {
    label: "Unremovable",
    icon: "Caution",
    next: ["confirmed_match"],
    title: "Unremovable data",
  },
};

const types = { ...STATUS_ENUM };

const tagVariants = cva(
  "flex w-fit flex-row items-center rounded-full px-2 py-1 text-gray-800 dark:text-white",
  {
    variants: {
      variant: {
        default: "dark:bg-primary-900 bg-gray-100",
        status: "",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface Props extends HTMLProps<HTMLAnchorElement>, VariantProps<typeof tagVariants> {
  type?: StatusEnumType;
  color?: string;
  icon?: string;
  label?: string;
  href?: string;
}

export const Tag = ({ type, color, icon, label, href, className, ref, ...props }: Props) => {
  const isStatus = type ? Boolean(STATUS_ENUM[type]) : undefined;
  const typeProps = type ? types[type] : undefined;

  color = color || typeProps?.color;
  icon = icon || typeProps?.icon;
  label = label || typeProps?.label;

  if (!label) return null;

  const content = (
    <>
      {icon && <Icon icon={icon} color={color} className="mr-1" />}
      <label className="cursor-pointer text-ellipsis whitespace-nowrap" style={{ color }}>
        {label}
      </label>
    </>
  );

  const tagClasses = cn(
    tagVariants({ variant: isStatus ? "status" : "default", className }),
    "px-2 py-1",
    "hover:opacity-80",
    href && "cursor-pointer hover:underline",
  );

  const style = {
    backgroundColor: color ? `${color}19` : undefined,
    border: color || isStatus ? "none" : undefined,
  };

  if (href) {
    return (
      <Link
        href={href}
        className={tagClasses}
        style={style}
        {...props}
        ref={ref as React.Ref<HTMLAnchorElement>}
      >
        {content}
      </Link>
    );
  }

  const tagProps = {
    ...props,
    ref: ref as React.Ref<HTMLDivElement>,
  } as React.HTMLAttributes<HTMLDivElement>;

  return (
    <div className={tagClasses} style={style} {...tagProps}>
      {content}
    </div>
  );
};
