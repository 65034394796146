import z from "zod";

import { Color } from "./colors";
import { IsBrokerCoveredFunctionTypeSchema } from "./enum/brokers";

export const FeatureSetSchema = z.object({
  hasPeopleSearchScans: z.boolean(), // i.e. scraper results
  peopleSearchScanFrequencyDays: z.number(), // currently just monthly is supported
  hasManualRemovals: z.boolean(), // i.e. opt-out forms filled out by the ops team
  canViewAtlasMail: z.boolean(), // if hasTakedownTemplateEngine is true, this must also be true
  hasTakedownTemplateEngine: z.boolean(), // i.e. DL, CCPA takedowns using AtlasMail
  canViewDarkweb: z.enum(["none", "redacted", "full"]), // redacted means can view breaches, but not passwords
  canInviteFamily: z.boolean(),
  maxFamilyMembers: z.number(), // default 5
  hasIdentityTheftInsurance: z.boolean(),
  hasIdentityTheftInsuranceForSpouse: z.boolean(),
  identityTheftMaxMembers: z.number(),
  canViewSerps: z.boolean(), // coming soon
  hasSerpsOptouts: z.boolean(), // coming soon
});
/**
 * Define a set of features that a user has, based on their plan
 */
export type FeatureSet = z.infer<typeof FeatureSetSchema>;

interface SubscriptionDefinitionsMapInterface {
  [key: string]: SubscriptionDefinitionInterface;
}

export const SubscriptionDefinitionSchema = z.object({
  display: z.object({
    label: z.string(),
    color: z.nativeEnum(Color),
    icon: z.string().optional(),
  }),
  isPremium: z.boolean(),
  features: FeatureSetSchema,
  isBrokerCoveredFunction: z.lazy(() => IsBrokerCoveredFunctionTypeSchema),
});
export type SubscriptionDefinitionInterface = z.infer<typeof SubscriptionDefinitionSchema>;

const genericFreeFeatures: FeatureSet = {
  hasPeopleSearchScans: true,
  peopleSearchScanFrequencyDays: 30,
  hasManualRemovals: false,
  canViewAtlasMail: true,
  hasTakedownTemplateEngine: false,
  canViewDarkweb: "redacted",
  canInviteFamily: false,
  maxFamilyMembers: 0,
  hasIdentityTheftInsurance: false,
  hasIdentityTheftInsuranceForSpouse: false,
  identityTheftMaxMembers: 0,
  canViewSerps: true,
  hasSerpsOptouts: false,
};

const genericPremiumFeatures: FeatureSet = {
  hasPeopleSearchScans: true,
  peopleSearchScanFrequencyDays: 30,
  hasManualRemovals: true,
  canViewAtlasMail: true,
  hasTakedownTemplateEngine: false,
  canViewDarkweb: "full",
  canInviteFamily: true,
  maxFamilyMembers: 5,
  hasIdentityTheftInsurance: false,
  hasIdentityTheftInsuranceForSpouse: false,
  identityTheftMaxMembers: 0,
  canViewSerps: true,
  hasSerpsOptouts: true,
};

export const SubscriptionDefinitions: SubscriptionDefinitionsMapInterface = {
  PUBLIC_FREE: {
    display: {
      label: "Free Tier",
      color: Color.Red,
    },
    isPremium: false,
    features: genericFreeFeatures,
    isBrokerCoveredFunction: "standardTierBrokers",
  },
  LEO_FREE: {
    display: {
      label: "Free Tier",
      color: Color.Red,
    },
    isPremium: false,
    features: genericFreeFeatures,
    isBrokerCoveredFunction: "standardTierBrokers",
  },
  PUBLIC_PREMIUM: {
    display: {
      label: "Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: genericPremiumFeatures,
    isBrokerCoveredFunction: "allPremiumBrokers",
  },
  LEO_PREMIUM: {
    display: {
      label: "Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: genericPremiumFeatures,
    isBrokerCoveredFunction: "allPremiumBrokers",
  },

  // member of the PBA with ULPP
  NJ_PBA_PREMIUM: {
    display: {
      label: "NJSPBA Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: true,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "allPremiumBrokers",
  },

  // Copy of NJ_PBA_PREMIUM
  NJ_PBA105_PREMIUM: {
    display: {
      label: "NJSPBA 105 Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: true,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "allPremiumBrokers",
  },
  // Copy of NJ_PBA_RETIREE_FREE
  NJ_PBA105_RETIREE_FREE: {
    display: {
      label: "NJSPBA 105 Basic",
      icon: "CheckShield",
      color: Color.Orange,
    },
    isPremium: false,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "redacted",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: false,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "basicTierBrokers",
  },
  // Copy of NJ_PBA_RETIREE_FREE
  NJ_NJSHERIFFS_FREE: {
    display: {
      label: "NJ Sheriffs Basic",
      icon: "CheckShield",
      color: Color.Orange,
    },
    isPremium: false,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "redacted",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: true,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "basicTierBrokers",
  },

  // Copy of NJ_PBA_PREMIUM
  MTA_PBA_PREMIUM: {
    display: {
      label: "MTA PBA Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: true,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: true,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "allPremiumBrokers",
  },

  NY_SUFFOLK_PBA_FREE: {
    display: {
      label: "Suffolk PBA Free",
      icon: "CheckShield",
      color: Color.Red,
    },
    isPremium: false,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "full",
      canInviteFamily: false,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: false,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "noBrokers",
  },

  NY_SUFFOLK_PBA_PREMIUM: {
    display: {
      label: "Suffolk PBA Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: true,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: true,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "allPremiumBrokers",
  },

  NJ_OCPO_PREMIUM: {
    display: {
      label: "OCPO Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: true,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "allPremiumBrokers",
  },

  // retired free member of the PBA
  NJ_PBA_RETIREE_FREE: {
    display: {
      label: "NJSPBA Basic",
      icon: "CheckShield",
      color: Color.Orange,
    },
    isPremium: false,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "redacted",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: false,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "basicTierBrokers",
  },

  NJ_PBA_FREE: {
    display: {
      label: "NJSPBA Standard",
      icon: "CheckShield",
      color: Color.Orange,
    },
    isPremium: false,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "redacted",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: false,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "standardTierBrokers",
  },
  // someone who was PBA free tier and paid to upgrade
  NJ_PBA_PREMIUM_NO_ULPP: {
    display: {
      label: "NJSPBA Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: true,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "allPremiumBrokers",
  },

  NJ_STFA_FREE: {
    display: {
      label: "STFA Standard",
      color: Color.Orange,
    },
    isPremium: false,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "redacted",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: false,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "standardTierBrokers",
  },
  NJ_STFA_PREMIUM: {
    display: {
      label: "STFA Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    // NOTE: this is identical to NJ_PBA_PREMIUM
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: false,
      canViewAtlasMail: true,
      hasTakedownTemplateEngine: true,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: true,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "allPremiumBrokers",
  },
  NV_POACCSD_PREMIUM: {
    display: {
      label: "POACCSD Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: true,
      canViewAtlasMail: false,
      hasTakedownTemplateEngine: false,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: false,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "peopleSearchSitesOnly",
  },
  CANTOR_PREMIUM: {
    display: {
      label: "Cantor Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: genericPremiumFeatures,
    isBrokerCoveredFunction: "allPremiumBrokers",
  },
  SOURCED_INTELLIGENCE_PREMIUM: {
    display: {
      label: "Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: genericPremiumFeatures,
    isBrokerCoveredFunction: "allPremiumBrokers",
  },
  SUNDAY_SECURITY_PREMIUM: {
    display: {
      label: "Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: genericPremiumFeatures,
    isBrokerCoveredFunction: "allPremiumBrokers",
  },
  VOTEVETS_BASIC: {
    display: {
      label: "VoteVets Basic",
      color: Color.Red,
    },
    isPremium: false,
    features: genericFreeFeatures,
    isBrokerCoveredFunction: "standardTierBrokers",
  },
  VOTEVETS_PREMIUM: {
    display: {
      label: "VoteVets Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: genericPremiumFeatures,
    isBrokerCoveredFunction: "allPremiumBrokers",
  },
  WA_SPOG_PREMIUM: {
    display: {
      label: "SPOG Premium",
      icon: "CheckShield",
      color: Color.Blue,
    },
    isPremium: true,
    features: {
      hasPeopleSearchScans: true,
      peopleSearchScanFrequencyDays: 30,
      hasManualRemovals: true,
      canViewAtlasMail: false,
      hasTakedownTemplateEngine: false,
      canViewDarkweb: "full",
      canInviteFamily: true,
      maxFamilyMembers: 5,
      hasIdentityTheftInsurance: false,
      hasIdentityTheftInsuranceForSpouse: false,
      identityTheftMaxMembers: 0,
      canViewSerps: true,
      hasSerpsOptouts: true,
    },
    isBrokerCoveredFunction: "peopleSearchSitesOnly",
  },
};

const keys = Object.keys(SubscriptionDefinitions);
export const SubscriptionTypeSchema = z.enum([keys[0], ...keys.slice(1)]);
export type SubscriptionType = z.infer<typeof SubscriptionTypeSchema> & string;
export const AllSubscriptionTypes = Object.keys(SubscriptionDefinitions) as Array<SubscriptionType>;
