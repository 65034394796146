import "../lib/array";
import "@atlas/ui/style";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ErrorBoundary as RollbarErrorBoundary, Provider as RollbarProvider } from "@rollbar/react";
import dayjs from "dayjs";
import dayjsUtc from "dayjs/plugin/utc";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { ToastContainer } from "react-toastify";
import { BareFetcher, SWRConfig } from "swr";

import { config } from "@atlas/app-config";
import { PageLoadingIndicator } from "@atlas/ui/PageLoadingIndicator";

import { AnalyticsProvider } from "../context/analytics";
import { AdminLayout } from "../layout/Admin";
import { AppLayout } from "../layout/App";

dayjs.extend(dayjsUtc);

export interface PageWithTitle {
  title: string;
  noIndex?: boolean;
}

const DEFAULT_FETCHER: BareFetcher<any> = (resource, init) =>
  fetch(resource, { credentials: "include", ...init }).then((res) => res.json());

type RootProps = AppProps & { env: string; gitHash: string };

const Root = (props: RootProps) => {
  const router = useRouter();
  const rollbarConfig = {
    accessToken: "05f65e1ca23c4433b27940bf2e34ed3f",
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: props.env,
    // disable on admin and dev envs
    enabled: config.rollbar.enabled && !router.asPath.startsWith("/admin"),
    version: process.env.GIT_HASH || "",
  };

  return (
    <RollbarProvider config={rollbarConfig}>
      <RollbarErrorBoundary>
        <App {...props} />
      </RollbarErrorBoundary>
    </RollbarProvider>
  );
};

const App = (props: RootProps) => {
  const router = useRouter();
  const { pageProps } = props;

  const isAdmin = router.asPath.startsWith("/admin");

  const theme = createTheme({
    palette: {
      primary: {
        main: "#116df8",
      },
      ...(isAdmin
        ? {
            mode: "dark",
            background: {
              paper: "#161a2e",
            },
          }
        : null),
    },
  });

  const Layout = isAdmin ? AdminLayout : AppLayout;

  return (
    <ThemeProvider theme={theme}>
      <SWRConfig
        value={{
          fetcher: DEFAULT_FETCHER,
        }}
      >
        <Head>
          {pageProps.noIndex && <meta name="robots" content="noindex" />}
          <title>{pageProps.title ? `${pageProps.title} | Atlas Privacy` : "Atlas Privacy"}</title>
          <meta
            content="Atlas removes your personal info from 150+ data broker websites. Protect yourself and your family by removing your information from the internet."
            name="description"
          />
          <meta content="Atlas Privacy" property="og:title" />
          <meta
            content="Protect your privacy. Remove yourself from 150+ data brokers."
            property="og:description"
          />
          <meta content="Atlas Privacy" property="twitter:title" />
          <meta
            content="Protect your privacy. Remove yourself from 150+ data brokers."
            property="twitter:description"
          />
          <meta property="og:type" content="website" />
          <link rel="apple-touch-icon" sizes="180x180" href="/img/favicon/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/img/favicon/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/img/favicon/favicon-16x16.png" />
          <link rel="icon" href="/img/icon.png" />
          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        </Head>
        <PageLoadingIndicator override={pageProps.pageLoadingIndicatorOverride} />
        <ToastContainer position="top-center" autoClose={2400} hideProgressBar={true} />

        <AnalyticsProvider>
          <div
            className={`flex min-h-screen flex-col ${isAdmin ? "bg-primary-black dark" : "bg-neutral-secondary"}`}
          >
            <main className={`grow`}>
              <Layout {...props} />
            </main>
          </div>
        </AnalyticsProvider>
      </SWRConfig>
    </ThemeProvider>
  );
};

Root.getInitialProps = async ({ Component, ctx }) => {
  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx)
    : { user: null, identity_id: null };

  const env = process.env.NEXT_PUBLIC_ENV || "local";

  return { pageProps, env };
};

export default Root;
