import Hotjar from "@hotjar/browser";
import { AnalyticsBrowser } from "@segment/analytics-next";
import React, { createContext, useContext, useMemo } from "react";

import { config } from "@atlas/app-config";
import { useMountEffect } from "@atlas/hooks/useMountEffect";
import { EventType } from "@atlas/lib/events";

interface AnalyticsContextProps {
  analytics: AnalyticsBrowser;
}

const defaultAnalyticsContext = {
  analytics: new AnalyticsBrowser(),
};

export const AnalyticsContext = createContext<AnalyticsContextProps>(defaultAnalyticsContext);

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export const AnalyticsProvider = ({ children }: AnalyticsProviderProps) => {
  const writeKey = config.segment.writeKey;

  const value: AnalyticsContextProps = useMemo(
    () => ({ analytics: AnalyticsBrowser.load({ writeKey }) }),
    [writeKey],
  );

  useMountEffect(() => {
    if (config.hotjar.enabled && typeof Hotjar !== "undefined") {
      Hotjar.init(config.hotjar.hjid, 6);
    }
  });

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
};

export const useAnalytics = () => {
  const { analytics } = useContext(AnalyticsContext);

  const { logNavigation, logEvent } = useMemo(() => {
    function logNavigation(Route: string) {
      if (!config.segment.enabled) return;
      analytics.page({ name: Route });
    }

    function logEvent<Name extends EventType["name"]>(
      name: Name,
      properties: Extract<EventType, { name: typeof name }>["properties"],
    ): void {
      if (!config.segment.enabled) return;
      analytics.track(name, properties);
    }

    return {
      logNavigation,
      logEvent,
    };
  }, [analytics]);

  return {
    analytics,
    logNavigation,
    logEvent,
  };
};
