/* eslint-disable func-names */
/* eslint-disable no-extend-native */
/* eslint-disable no-unused-vars */

export {};

declare global {
  interface ArrayConstructor {
    range(start: number, end: number): number[];
  }

  interface Array<T> {
    max(): number;
    min(): number;
    unique(): T[];
    sum(): number;
    minIndex(): number;
  }
}

Array.prototype.max = function () {
  if (!this || !this.length) return 0;
  return Math.max(...this) || 0;
};

Array.prototype.min = function () {
  if (!this || !this.length) return 0;
  return Math.min(...this) || 0;
};

Array.prototype.unique = function () {
  return [...new Set(this)];
};

Array.prototype.sum = function () {
  return this.reduce((a, b) => a + b, 0);
};

Array.prototype.minIndex = function () {
  let min;
  let minIndex = -1;
  let index = -1;
  for (const value of this) {
    ++index;
    if (value !== null && (min > value || min === undefined)) {
      min = value;
      minIndex = index;
    }
  }
  return minIndex;
};

Array.range = function (start: number, end: number) {
  return Array(end - start + 1)
    .fill(undefined)
    .map((_, i) => i + start);
};
