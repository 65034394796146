import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import useSWR from "swr/immutable";

import { config } from "@atlas/app-config";
import { getAdminAbility } from "@atlas/lib/authorization/adminAbility";
import { AbilityProvider } from "@atlas/lib/authorization/context";

import { AdminNavbar } from "../pods/admin/components/AdminNavbar";

export function AdminLayout({ Component, pageProps }) {
  const { data, isLoading } = useSWR(`${config.api_url}/admin/me`);

  const adminAbility = getAdminAbility((data && data.admin) ?? {});

  if (isLoading) {
    return (
      <div id="main-content" className="main admin" data-attr="main">
        <div className="container">Loading....</div>
      </div>
    );
  }

  return (
    <AbilityProvider value={adminAbility}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AdminNavbar />
        <div id="main-content" className="main admin" data-attr="main">
          <div className="mx-auto max-w-[85rem] px-4 pt-4 md:px-8 md:pt-8">
            <Component {...pageProps} />
          </div>
        </div>
      </LocalizationProvider>
    </AbilityProvider>
  );
}
