// A drop-in replacement for react-toastify.

import { toast as reactToast, ToastOptions, ToastContent } from "react-toastify";

type Options = ToastOptions<{}> | undefined;

const success = <TData = unknown>(content: ToastContent<TData>, options?: Options) => {
  reactToast.success(content, options);
};

const error = <TData = unknown>(content: ToastContent<TData>, options?: Options) => {
  reactToast.error(content, options);
};

const info = <TData = unknown>(content: ToastContent<TData>, options?: Options) => {
  reactToast.info(content, options);
};

const dismiss = () => {
  reactToast.dismiss();
};

export const toast = {
  success,
  error,
  info,
  dismiss,
};
