import { Drawer } from "@mui/material";
import { createContext, useState } from "react";

import { User } from "@atlas/service/account";
import { Icon } from "@atlas/ui/Icon";

import { useFeatureFlag } from "../../context/statsig";

import { Menus } from "./Menus";

interface DrawerControls {
  open?: boolean;
  label?: string;
  dataAttr?: string;
  anchor?: "top" | "bottom" | "left" | "right";
  items?: string[];
}

export const DrawerController = createContext<{
  get?: DrawerControls;
  set?: (x: DrawerControls) => void;
}>({});

export const ControlledDrawer = ({
  children,
  user,
}: {
  children: React.ReactNode;
  user: User | null;
}) => {
  const [drawer, setDrawer] = useState<DrawerControls>({});
  const toggle = () => setDrawer({ ...drawer, open: !drawer?.open });
  const { value: newLeftNav } = useFeatureFlag("new_left_nav");

  let content = (
    <>
      <div className="mb-8 flex items-center justify-between px-8 pt-8">
        <h1>{drawer?.label}</h1>
        <Icon icon="Close" onClick={toggle} className="size-6" />
      </div>
      <Menus menus={drawer?.items || []} user={user} drawer />
    </>
  );

  if (newLeftNav) {
    content = (
      <>
        <div className="flex min-h-[64px] items-center justify-between border-b border-gray-200 px-4">
          <Icon
            icon="x-circle-solid"
            className="size-6 hover:opacity-80"
            aria-label="Close"
            role="button"
            onClick={toggle}
          />
        </div>
        <Menus menus={drawer?.items || []} user={user} drawer />
      </>
    );
  }

  return (
    <DrawerController.Provider value={{ get: drawer, set: setDrawer }}>
      {children}
      <Drawer
        anchor={drawer?.anchor}
        open={drawer?.open}
        onClose={toggle}
        data-attr="mobile-menu"
        PaperProps={{
          sx: {
            width: "100%",
          },
        }}
      >
        {content}
      </Drawer>
    </DrawerController.Provider>
  );
};
