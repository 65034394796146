import { createContext, useContext } from "react";

import { AppAbility } from "./adminAbility";

export const AbilityContext = createContext<AppAbility | null>(null);
export const AbilityProvider = AbilityContext.Provider;

export const useAbility = (): AppAbility => {
  const ability = useContext(AbilityContext);
  if (!ability) throw new Error("No ability provided");
  return ability;
};
