import Link from "next/link";
import { NextRouter, useRouter } from "next/router";
import { useContext } from "react";

import { config } from "@atlas/app-config";
import { User } from "@atlas/service/account";
import { cn } from "@atlas/ui/helpers";
import { Icon } from "@atlas/ui/Icon";

import { useAnalytics } from "../../context/analytics";
import { useCurrentUser } from "../../context/user";

import { DrawerController } from "./DrawController";
import { Menu } from "./Menu";
import { Tab } from "./UserNavbar";

const BaseMenuComponent = ({
  label,
  icon,
  href,
  dataAttr,
  onClick,
  isVisible,
  selected,
  user,
  drawer,
  router,
  isDivider,
  className,
  iconClassName,
  labelClassName,
  nestedMenu,
  mobile,
  isHeader,
  bubble,
}: Menu & {
  router: NextRouter;
  selected?: boolean;
  user: User | null;
  className?: string;
  iconClassName?: string;
  labelClassName?: string;
  mobile?: boolean;
}) => {
  const drawerControl = useContext(DrawerController);
  if (isVisible && !isVisible(user, router)) return null;
  if (isDivider) return <div className="my-4 w-full border-b border-gray-200" />;

  const Component = ({ children, ...props }: any) =>
    href ? (
      <Link
        {...props}
        className={cn(
          "text-primary-dark cursor-pointer font-semibold no-underline hover:opacity-90",
          props.className,
        )}
      >
        {children}
      </Link>
    ) : (
      <div
        {...props}
        className={cn("text-primary-dark cursor-pointer font-semibold", props.className)}
      >
        {children}
      </div>
    );

  return (
    <>
      <Component
        key={dataAttr}
        href={href}
        className={cn(
          "flex cursor-pointer",
          selected && "text-primary rounded bg-[#eef5ff]",
          isHeader && "text-gray-dark mt-4 text-[1.0625rem] font-semibold",
          label === "Log Out" && "rounded-none border-l border-[#e4e7e9]",
          className,
        )}
        data-attr={dataAttr}
        onClick={(event) => {
          if (onClick || drawer) event.preventDefault();
          if (drawerControl.set) {
            if (drawer) {
              drawerControl.set({
                ...drawer,
                dataAttr,
                open: !drawerControl?.get?.open,
              });
            } else drawerControl.set({ ...drawerControl.get, open: false });
          }
          if (onClick) onClick(event);
        }}
      >
        <div className="relative">
          <Icon
            icon={icon}
            className={cn(iconClassName, selected ? "text-primary" : "text-gray-500")}
          />
          {mobile && bubble && <div className="absolute -right-px top-[-6px]">{bubble}</div>}
        </div>
        <div className={cn(labelClassName, !mobile && "flex items-center gap-2")}>
          {typeof label === "function" ? label({ className: "text-sm" }) : label}
          {!mobile && bubble}
        </div>
      </Component>
      {Boolean(nestedMenu && !mobile && selected) && (
        <div className="ml-6 py-2 pl-6">
          {nestedMenu!
            .map((x) => Tab[x])
            .filter(Boolean)
            .map((menu) => (
              <DesktopMenuComponent
                {...menu}
                key={menu.dataAttr}
                router={router}
                selected={Boolean(menu.href && router.asPath?.startsWith(menu.href))}
                user={user}
                className="py-2"
              />
            ))}
        </div>
      )}
    </>
  );
};

const MobileMenuComponent = (
  props: Menu & { router: NextRouter; selected?: boolean; user: User | null },
) => (
  <BaseMenuComponent
    {...props}
    className="flex h-full flex-col items-center justify-center gap-[0.3rem] py-1.5"
    iconClassName="size-[1.8rem]"
    labelClassName="text-[10px] text-center"
  />
);

const DesktopMenuComponent = (
  props: Menu & { router: NextRouter; selected?: boolean; user: User | null },
) => (
  <BaseMenuComponent
    {...props}
    className={cn(
      "hover:bg-gray-lighter flex flex-row items-center gap-4 hover:rounded",
      !props.isHeader && "px-4 py-[0.6rem]",
    )}
    iconClassName="h-[25px] w-[25px]"
  />
);

const TopNavMenuComponent = (
  props: Menu & { router: NextRouter; selected?: boolean; user: User | null; mobile?: boolean },
) => (
  <BaseMenuComponent
    {...props}
    className={cn(
      "hover:bg-gray-lighter flex-row items-center gap-4",
      !props.mobile && "px-4 py-2",
      props.mobile && "ml-4",
    )}
    iconClassName={cn("size-4", props.mobile && "size-8")}
    labelClassName={cn("text-[0.9375rem] font-medium", props.mobile && "hidden")}
  />
);

export const Menus = ({
  menus,
  className,
  mobile,
  desktop,
  topNav,
  drawer,
}: {
  menus: string[];
  className?: string;
  mobile?: boolean;
  desktop?: boolean;
  user: User | null;
  topNav?: boolean;
  drawer?: boolean;
}) => {
  const user = useCurrentUser();
  const router = useRouter();
  const { analytics } = useAnalytics();

  let MenuComponent;
  if (mobile && !topNav) {
    MenuComponent = MobileMenuComponent;
  } else if (topNav) {
    MenuComponent = TopNavMenuComponent;
  } else {
    MenuComponent = DesktopMenuComponent;
  }

  return (
    <div
      className={cn(
        "flex",
        mobile &&
          !topNav &&
          "fixed inset-x-0 bottom-0 z-[1500] box-border h-[73px] w-full justify-between bg-white p-2 shadow-[0_0px_20px_rgba(0,0,0,0.1)] md:hidden",
        desktop && "hidden flex-col md:flex",
        drawer && "inset-x-0 bottom-0 top-auto flex-col px-8 pb-32",
        (desktop || drawer) && "h-full",
        drawer && "pt-8",
        (!mobile || topNav) && "justify-between",
        topNav && "flex-row",
        className,
      )}
    >
      <div
        className={cn(
          "flex w-full",
          (desktop || drawer) && "flex-col",
          mobile && !topNav && "justify-between",
        )}
      >
        {menus
          .map((x) => Tab[x])
          .filter((x) => x && !x.footer)
          .map((menu) => (
            <div key={menu.dataAttr} className="flex-1">
              <MenuComponent
                {...menu}
                href={menu.hrefLinkOverride || menu.href}
                onClick={
                  menu.label === "Log Out"
                    ? async () => {
                        try {
                          await analytics.reset();
                        } catch (e) {
                          // ignore it
                        }
                        window.location.replace(`${config.api_url}/user/session/logout`);
                      }
                    : undefined
                }
                router={router}
                selected={Boolean(menu.href && router.asPath?.startsWith(menu.href))}
                user={user}
                mobile={mobile}
              />
            </div>
          ))}
      </div>
      {menus.some((x) => Tab[x]?.footer) && (
        <div className="border-t border-gray-200 pt-4">
          {menus
            .map((x) => Tab[x])
            .filter((x) => x && x.footer)
            .map((menu) => (
              <MenuComponent {...menu} key={menu.dataAttr} router={router} user={user} />
            ))}
        </div>
      )}
    </div>
  );
};
