import { getCookie, removeCookies, deleteCookie, setCookie } from "cookies-next";
import { OptionsType } from "cookies-next/lib/types";
import { toast, TypeOptions } from "react-toastify";

export const USER_TOAST_MESSAGE_COOKIE = "usr_toast_message";

export interface UserToastMessage {
  message: string;
  type: TypeOptions;
}

/**
 * Generate a Toast Cookie for the user.
 */
export function createUserToastMessage(message: string, type: TypeOptions): UserToastMessage {
  return {
    message,
    type,
  };
}

/**
 * Check if the user has a Toast Cookie set. If so, display the message and clear the cookie.
 */
export function triggerUserToastMessage() {
  const cookie = getCookie(USER_TOAST_MESSAGE_COOKIE) as string | null;
  if (!cookie) return;

  const toastMessage = JSON.parse(cookie) as UserToastMessage;
  if (toastMessage && toastMessage.message) {
    toast(toastMessage.message, { type: toastMessage.type });
    removeCookies(USER_TOAST_MESSAGE_COOKIE);
  }
}

export const NEED_MORE_INFORMATION_COOKIE = "usr_need_more_information";
export const SHOWN_NEED_MORE_INFORMATION_COOKIE = "usr_shown_need_more_information";

export const shouldShowNeedMoreInfoDialog = (optsType: OptionsType): boolean => {
  const showNeedMoreInfoDialog =
    Boolean(getCookie(NEED_MORE_INFORMATION_COOKIE, optsType)) &&
    !getCookie(SHOWN_NEED_MORE_INFORMATION_COOKIE, optsType);
  if (showNeedMoreInfoDialog) {
    deleteCookie(NEED_MORE_INFORMATION_COOKIE, optsType);
    setCookie(SHOWN_NEED_MORE_INFORMATION_COOKIE, "true", optsType);
  }
  return showNeedMoreInfoDialog;
};
