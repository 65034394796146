import { Router } from "next/router";
import { useState } from "react";

import { useMountEffect } from "@atlas/hooks/useMountEffect";

const PageLoadingIndicator = ({ override }: { override?: string }) => {
  const [loading, setLoading] = useState(false);

  useMountEffect(() => {
    const handleRouteChangeStart = () => setLoading(true);
    const handleRouteChangeComplete = () => setLoading(false);
    const handleRouteChangeError = () => setLoading(false);

    Router.events.on("routeChangeStart", handleRouteChangeStart);
    Router.events.on("routeChangeComplete", handleRouteChangeComplete);
    Router.events.on("routeChangeError", handleRouteChangeError);

    return () => {
      Router.events.off("routeChangeStart", handleRouteChangeStart);
      Router.events.off("routeChangeComplete", handleRouteChangeComplete);
      Router.events.off("routeChangeError", handleRouteChangeError);
    };
  });

  return (
    <div
      className={`absolute left-0 top-0 h-1 w-screen overflow-hidden ${
        loading ? "" : "hidden"
      } ${override ?? ""}`}
    >
      <div className="bg-primary-lighter relative size-full">
        <div className="animate-indeterminate-first bg-primary absolute size-full" />
        <div className="animate-indeterminate-second bg-primary-light absolute size-full" />
      </div>
    </div>
  );
};

export { PageLoadingIndicator };
