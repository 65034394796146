import path from "path";

import { cva, type VariantProps } from "class-variance-authority";
import React from "react";

import { cn } from "./helpers";

let requireIcons = require.context("./icons", false, /.svg$/);

// Hotreload support
if (module.hot) {
  module.hot.accept(requireIcons.keys(), () => {
    requireIcons = require.context("./icons", false, /.svg$/);
  });
}

const Icons = Object.fromEntries(
  requireIcons.keys().map((file) => [path.parse(file).name, requireIcons(file).default]),
);

const iconVariants = cva("inline-block", {
  variants: {
    size: {
      default: "size-4",
      sm: "size-3",
      lg: "size-6",
      xl: "size-8",
      "no-size": "",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export interface IconProps
  extends React.SVGProps<SVGSVGElement>,
    VariantProps<typeof iconVariants> {
  icon?: string;
  className?: string;
  color?: string;
  onClick?: (e: React.MouseEvent<SVGSVGElement>) => void;
  id?: string;
}

export const Icon: React.FC<IconProps> = ({ icon, className, color, id, size, ...props }) => {
  if (!icon) return null;
  const Icon = Icons[icon];
  if (!Icon) return null;

  return (
    <Icon {...props} className={cn(iconVariants({ size }), className)} style={{ color }} id={id} />
  );
};
