import Link from "next/link";

import { config } from "@atlas/app-config";
import { SubscriptionDefinitions } from "@atlas/lib/subscription-types";
import { Icon } from "@atlas/ui/Icon";
import { Tag } from "@atlas/ui/Tag";

import { useCurrentUser } from "../../context/user";

const OrgLogo = ({ org }) => (
  <Link href={org.website || "#"} target="_blank" rel="external noreferrer">
    <img
      alt={org.name}
      src={`${config.org_pictures_url}/${org.org_id}/logo.png`}
      className="h-24 w-auto max-w-24 object-contain"
    />
  </Link>
);

export const UserSummary = () => {
  const user = useCurrentUser();

  if (!user) return null;

  return (
    <div className="hidden gap-5 border-b border-gray-200 py-4 md:flex">
      <div className="shrink-0">
        {user.organization?.org_id ? (
          <OrgLogo org={user.organization} />
        ) : user.familyOrg?.org_id ? (
          <OrgLogo org={user.familyOrg} />
        ) : (
          <Icon icon="DefaultProfile" className="size-24" />
        )}
      </div>
      <div className="flex flex-col justify-center gap-2">
        <Link href="/user/data-brokers" className="text-black hover:underline">
          <h3 className="text-base">
            {user.first_name} {user.last_name}
          </h3>
        </Link>
        {user.organization?.short_name ||
        user.familyOrg?.short_name ||
        user.is_invited_relative ||
        user.suborg ? (
          <>
            <p className="min-h-4 text-sm">
              {user.organization?.short_name || user.familyOrg?.short_name}
            </p>
            <p className="min-h-4 text-sm">
              {user.is_invited_relative ? "Family Member" : user.suborg}
            </p>
          </>
        ) : null}

        <div className="-ml-2.5 flex gap-4">
          {SubscriptionDefinitions[user.subscription_type] ? (
            <Tag
              {...SubscriptionDefinitions[user.subscription_type].display}
              data-attr={user.subscription.isPremium ? "status-premium" : "status-non-premium"}
            />
          ) : null}
          {user.is_top_customer ? <Tag label="VIP" color="#c3a240" /> : null}
        </div>
      </div>
    </div>
  );
};
