import { createContext, useContext } from "react";

const CurrentEnvContext = createContext<string>("");
export const useCurrentEnv = () => useContext(CurrentEnvContext);

interface CurrentEnvProviderProps {
  env: string;
  children: React.ReactNode;
}
export const CurrentEnvProvider = ({ children, env }: CurrentEnvProviderProps) => {
  return <CurrentEnvContext.Provider value={env}>{children}</CurrentEnvContext.Provider>;
};
