import copy from "copy-to-clipboard";
import { useState } from "react";

import { toast } from "@atlas/lib/toast";
import { cn } from "@atlas/ui/helpers";
import { Icon } from "@atlas/ui/Icon";

export const CopyToClipboard = ({ text }: { text: string }) => {
  const [hasClicked, setHasClicked] = useState(false);
  const onClick = () => {
    const result = copy(text);
    if (!result) {
      toast.error("Failed to copy to clipboard");
    } else {
      setHasClicked(true);
      setTimeout(() => {
        setHasClicked(false);
      }, 1000);
    }
  };
  return (
    <span
      className={cn(
        "inline-flex cursor-pointer items-center rounded border border-gray-300 px-1.5 py-0.5 hover:opacity-80",
        "CopyToClipboard", // Keeping the original class name for consistency
      )}
    >
      {hasClicked ? (
        <Icon icon="Check" className="text-green-500" />
      ) : (
        <Icon icon="Copy" className="copy-icon" onClick={onClick} />
      )}
    </span>
  );
};
