import { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useGate } from "statsig-react";

import { FeatureFlag } from "@atlas/service/statsig";

/**
 * Wrapper around Statsig's useGate hook that takes into account admin impersonation flag overrides
 */
export const useFeatureFlag = (featureId: FeatureFlag) => {
  const { value, isLoading } = useGate(featureId);
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error("useFeatureFlag must be used within a FeatureFlagProvider");
  }

  const { overrides } = context;
  const override = overrides[featureId];

  return { value: override ?? value, isLoading };
};

interface FeatureFlagContextValue {
  overrideFeatureFlag: (featureId: string, value: boolean) => void;
  overrides: Record<string, boolean>;
}

export const FeatureFlagContext = createContext<FeatureFlagContextValue | undefined>(undefined);

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const [overrides, setOverrides] = useState<Record<string, boolean>>({});

  useEffect(() => {
    // Load overrides from session storage after the component mounts
    const storedOverrides = sessionStorage.getItem("featureFlagOverrides");
    if (storedOverrides) {
      setOverrides(JSON.parse(storedOverrides));
    }
  }, []);

  const overrideFeatureFlag = (featureId: string, value: boolean) => {
    setOverrides((prevOverrides) => {
      const newOverrides = { ...prevOverrides, [featureId]: value };
      // Save overrides to session storage
      sessionStorage.setItem("featureFlagOverrides", JSON.stringify(newOverrides));
      return newOverrides;
    });
  };

  return (
    <FeatureFlagContext.Provider value={{ overrideFeatureFlag, overrides }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export const useOverrideFeatureFlag = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error("useOverrideFeatureFlag must be used within a FeatureFlagProvider");
  }

  return context.overrideFeatureFlag;
};

export const useFeatureFlagOverrides = () => {
  const context = useContext(FeatureFlagContext);

  if (!context) {
    throw new Error("useFeatureFlagOverrides must be used within a FeatureFlagProvider");
  }

  return context.overrides;
};
