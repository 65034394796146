/**
 * Simple set of Error classes that specify what kind of error
 * occurred, and an error handler that returns the error to the user
 * in the correct format.
 */

export interface StandardJsonResponse {
  message: string;
  status: string;
  details?: any;
}

interface ErrorProps {
  debugMessage?: string;
  userMessage?: string;
  userDetails?: string;
}

export class AbstractError extends Error {
  httpStatusCode: number = 500;
  userMessage: string;
  debugMessage: string;
  userDetails: string | undefined;

  constructor({
    debugMessage = "An error occurred",
    userMessage = "An error occurred",
    userDetails,
  }: ErrorProps) {
    super(debugMessage);
    this.userMessage = userMessage;
    this.userDetails = userDetails;
    this.debugMessage = debugMessage;
  }

  getHttpStatusCode() {
    return this.httpStatusCode;
  }

  getErrorJson(): StandardJsonResponse {
    return {
      status: "ERROR",
      message: this.userMessage,
      details: this.userDetails ?? undefined,
    };
  }
}

export class InternalServerError extends AbstractError {}

export class ArgumentError extends AbstractError {
  constructor(params: ErrorProps) {
    if (!params.userMessage) params.userMessage = "Invalid Argument";
    super(params);
    this.httpStatusCode = 400;
  }
}

// create similar for ValidationError NotFoundError NotPermittedError

export class NotFoundError extends AbstractError {
  constructor(params: ErrorProps & {}) {
    if (!params.userMessage) params.userMessage = "Not Found";
    super(params);
    this.httpStatusCode = 404;
  }
}

export class NotPermittedError extends AbstractError {
  constructor(params: ErrorProps) {
    if (!params.userMessage) params.userMessage = "Not Permitted";
    super(params);
    this.httpStatusCode = 403;
  }
}

/**
 * Structured data to send back to the user when there is a validation error.
 */
export interface ValidationErrorData {
  message: string; // user-facing message
  field: string;
}
export class ValidationError extends AbstractError {
  errors: ValidationErrorData[];
  constructor(params: ErrorProps & { errors?: ValidationErrorData[] }) {
    if (!params.userMessage) params.userMessage = "Invalid Input";
    super(params);
    this.httpStatusCode = 400;
    this.errors = params.errors || [];
  }

  getErrorJson() {
    return {
      message: this.userMessage,
      status: "ERROR",
      errors: this.errors,
    };
  }
}

export class AuthenticationError extends AbstractError {
  constructor(params: ErrorProps) {
    if (!params.userMessage) params.userMessage = "Authentication Error";
    super(params);
    this.httpStatusCode = 401;
  }
}

export class AlreadyInUseError extends AbstractError {
  constructor(params: ErrorProps) {
    if (!params.userMessage) params.userMessage = "Already In Use";
    super(params);
    this.httpStatusCode = 401;
  }
}

export class RateLimitedError extends AbstractError {
  constructor(params: ErrorProps) {
    if (!params.userMessage) params.userMessage = "Rate Limited";
    super(params);
    this.httpStatusCode = 429;
  }
}
