/* eslint-disable @typescript-eslint/no-unused-vars */
import { cva, VariantProps } from "class-variance-authority";
import Link from "next/link";
import React, { AnchorHTMLAttributes, useMemo } from "react";

import { cn } from "./helpers";
import { Icon } from "./Icon";

const colors = ["primary", "red", "orange", "neutral-primary", "neutral-secondary"] as const;
type Color = (typeof colors)[number];

const variants = ["primary", "secondary", "tertiary"] as const;
type Variant = (typeof variants)[number];

type CompoundVariant = {
  variant: Variant;
  color: Color;
  class: string;
};

const variantRules: Record<Variant, (color: Color) => string> = {
  primary: (color) =>
    cn(
      "hover:opacity-80",
      `bg-${color}`,
      "disabled:bg-gray-light",
      "dark:hover:opacity-80",
      "disabled:text-gray-darker",
    ),
  secondary: (color) =>
    cn(
      `border-${color}`,
      `hover:opacity-60`,
      "border-opacity-40",
      `text-${color}`,
      `hover:border-${color}`,
      "dark:bg-primary-black",
      "dark:hover:opacity-80",
    ),
  tertiary: (color) =>
    cn(
      `text-${color}`,
      `hover:bg-${color === "primary" ? "gray-100" : `${color}-50`}`,
      "dark:hover:bg-primary-darkest",
    ),
};

const generateCompoundVariants = (): CompoundVariant[] =>
  variants.flatMap((variant) =>
    colors.map((color) => ({
      variant,
      color,
      class: variantRules[variant](color),
    })),
  );

const buttonVariants = cva(
  cn(
    "inline-flex cursor-pointer select-none items-center justify-center",
    "gap-2.5 rounded px-3 py-2 text-[1.0625rem] font-semibold",
    "transition-all disabled:cursor-not-allowed",
  ),
  {
    variants: {
      variant: {
        primary: "text-white disabled:text-white dark:text-white",
        secondary: "border bg-white",
        tertiary: "bg-transparent font-bold shadow-none",
      },
      color: Object.fromEntries(colors.map((color) => [color, ""])),
      size: {
        default: "h-12 lg:h-10",
        large: "p-6",
        wide: "w-full",
        tall: "max-h-12 min-h-12",
      },
      disabled: {
        true: "border-gray/50 bg-gray/20 text-gray-darker/60 border",
      },
    },
    compoundVariants: generateCompoundVariants(),
    defaultVariants: {
      variant: "primary",
      color: "primary",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    VariantProps<typeof buttonVariants> {
  href?: string;
  loading?: boolean;
  hrefTarget?: string;
  iconLeft?: string;
  iconLeftClassName?: string;
  iconRight?: string;
  iconRightClassName?: string;
  formAction?: any;
  color?: (typeof colors)[number];
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  linkType?: "button" | "link";
}

const Button = React.forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      className,
      disabled,
      href,
      iconLeft,
      iconLeftClassName,
      iconRight,
      iconRightClassName,
      loading,
      onClick,
      hrefTarget,
      linkType = "button",
      variant,
      color,
      size,
      ...props
    },
    ref,
  ) => {
    const isDisabled = useMemo(() => disabled || loading, [disabled, loading]);

    const content = (
      <>
        {iconLeft && <Icon className={cn("size-6", iconLeftClassName)} icon={iconLeft} />}
        {children}
        {loading && <Icon className="size-6 animate-spin" icon="RingLoader" />}
        {iconRight && !loading && (
          <Icon className={cn("size-6", iconRightClassName)} icon={iconRight} />
        )}
      </>
    );

    const componentProps = {
      ...props,
      onClick: isDisabled ? undefined : onClick,
      className: cn(
        buttonVariants({ variant, color, size, disabled: isDisabled }),
        linkType === "link" && "bg-transparent shadow-none",
        className,
      ),
      disabled: isDisabled,
    };

    if (href) {
      return (
        <Link
          href={isDisabled ? "#" : href}
          {...componentProps}
          target={hrefTarget}
          ref={ref as React.Ref<HTMLAnchorElement>}
        >
          {content}
        </Link>
      );
    }

    const buttonProps = {
      ...componentProps,
      ref: ref as React.Ref<HTMLButtonElement>,
      type: props.type as "button" | "submit" | "reset" | undefined,
    } as React.ButtonHTMLAttributes<HTMLButtonElement>;

    return <button {...buttonProps}>{content}</button>;
  },
);

Button.displayName = "Button";

export { Button, buttonVariants };
